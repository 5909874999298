import { useEffect, useMemo, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { type Media, MediaFormatVersion } from '@lp-lib/game';

import { useInstance } from '../../../hooks/useInstance';
import { useListLoader } from '../../../hooks/useListLoader';
import { apiService } from '../../../services/api-service';
import {
  type DetailDrawingListResponse,
  type DrawingSearchParams,
} from '../../../services/api-service/drawing.api';
import { type DetailDrawing, type TimelinePoint } from '../../../types/drawing';
import { sleep, xDomainifyUrl } from '../../../utils/common';
import { DateUtils } from '../../../utils/date';
import { MediaUtils } from '../../../utils/media';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ErrorMessage } from '../../Game/GameCenter';
import { Loading } from '../../Loading';
import { OrganizationSelect } from '../../Organization';
import { parseStreamId } from '../../Session';
import { DrawingCanvas } from '../drawing';

function getCanvasBgUrl(media: Media | null): string | null {
  const url = MediaUtils.PickMediaUrl(media, {
    priority: [MediaFormatVersion.HD, MediaFormatVersion.Raw],
  });
  return url ? xDomainifyUrl(url, 'drawing') : null;
}

function DrawingDetailModal(props: {
  drawing: DetailDrawing;
  onComplete: () => void;
  onClose: () => void;
}) {
  const { drawing } = props;
  const [replay, setReplay] = useState<'noop' | 'replaying' | 'done'>('noop');
  const ref = useRef(null);
  const cvs = useInstance(() => {
    return new DrawingCanvas(512, 512, {
      background: getCanvasBgUrl(drawing.data.backgroundMedia),
      className: 'rounded-lg w-full h-full',
    });
  });
  const brush = useInstance(() => cvs.addBrush('my', '#000000'));

  useEffect(() => {
    if (!ref.current) return;
    cvs.attach(ref.current);
    return () => cvs.detach();
  }, [cvs]);

  const downloadDrawing = async () => {
    if (!drawing.artworkMedia) return;
    const image = await fetch(drawing.artworkMedia.url);
    const blob = await image.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${Date.now()}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // TODO: use RafLoop
  const replayV0 = async () => {
    cvs.reset();
    const speed = 1000 / 100;
    for (const path of drawing.data.paths) {
      if (path.points.length === 0) continue;
      brush.brushColor = path.brushColor;
      brush.brushSize = path.brushSize;
      if (path.points.length === 1) {
        await sleep(speed);
        cvs.drawCircle(brush.id, path.points[0]);
        continue;
      }
      cvs.startPath(brush.id, path.points[0]);
      for (const point of path.points) {
        await sleep(speed);
        cvs.movePath(brush.id, point);
      }
      cvs.endPath(brush.id);
    }
  };

  // TODO: use RafLoop
  const replayV1 = async () => {
    cvs.reset();
    let prev: TimelinePoint | null = null;
    const wait = async (curr: TimelinePoint) => {
      await sleep(prev ? curr.ts - prev.ts : 0);
      prev = curr;
    };
    for (const path of drawing.data.paths) {
      if (path.points.length === 0) continue;
      brush.brushColor = path.brushColor;
      brush.brushSize = path.brushSize;
      if (path.points.length === 1) {
        await wait(path.points[0]);
        cvs.drawCircle(brush.id, path.points[0]);
        continue;
      }
      await wait(path.points[0]);
      cvs.startPath(brush.id, path.points[0]);
      for (const point of path.points) {
        await wait(point);
        cvs.movePath(brush.id, point);
      }
      cvs.endPath(brush.id);
    }
  };

  const replayDrawing = async () => {
    if (!ref.current) return;
    setReplay('replaying');
    try {
      if (drawing.data.version === 0) {
        await replayV0();
      } else if (drawing.data.version === 1) {
        await replayV1();
      } else {
        throw new Error('Unknown drawing version');
      }
    } catch (error) {
      throw error;
    } finally {
      setReplay('done');
    }
  };

  return (
    <div
      className='flex flex-col gap-10 items-center justify-center 
    bg-black px-24 pt-20 pb-10 rounded-xl border border-[#303436] relative'
    >
      <button
        className='btn-secondary w-30 h-10 absolute top-2 right-2'
        onClick={() => props.onClose()}
      >
        Close
      </button>
      <div className='text-xl text-center text-white'>
        {drawing.data.prompt}
      </div>
      <div
        ref={ref}
        style={{
          width: drawing.data.width,
          height: drawing.data.height,
          display: replay !== 'noop' ? 'block' : 'none',
        }}
      ></div>
      <img
        width={drawing.data.width}
        height={drawing.data.height}
        src={drawing.artworkMedia?.url}
        alt='drawing'
        style={{
          display: replay === 'noop' ? 'block' : 'none',
        }}
      />
      <div className='flex items-center justify-center gap-2'>
        <button className='btn-primary w-40 h-10' onClick={downloadDrawing}>
          Download
        </button>
        <button
          className='btn-warning w-40 h-10'
          onClick={replayDrawing}
          disabled={replay === 'replaying'}
        >
          {replay === 'replaying' ? 'Replaying' : 'Replay'}
        </button>
      </div>
    </div>
  );
}

function DrawingItem(props: { drawing: DetailDrawing }) {
  const { drawing } = props;
  const url = drawing.artworkMedia?.url;
  const triggerFullscreenModal = useAwaitFullScreenConfirmCancelModal();

  const onDrawingClick = () => {
    triggerFullscreenModal({
      kind: 'custom',
      containerClassName: 'bg-black bg-opacity-60',
      element: (p) => (
        <DrawingDetailModal
          drawing={drawing}
          onComplete={p.internalOnConfirm}
          onClose={p.internalOnCancel}
        />
      ),
    });
  };

  return (
    <tr className='text-sms'>
      <td className='py-1.5'>{drawing.orgName || 'N/A'}</td>
      <td className='py-1.5'>{drawing.username || 'unknown'}</td>
      <td className='py-1.5'>
        {DateUtils.FormatDatetime(drawing.sessionStartedAt)}
      </td>
      <td className='py-1.5'>{drawing.data.prompt}</td>
      <td className='py-1.5'>
        {url && (
          <button className='btn' onClick={onDrawingClick}>
            <img width={64} height={64} src={url} alt='drawing' />
          </button>
        )}
      </td>
    </tr>
  );
}

export function PaginatedList(props: {
  searchParams?: DrawingSearchParams;
}): JSX.Element | null {
  const { searchParams } = props;
  const paginator = useMemo(
    () => apiService.drawing.search(searchParams),
    [searchParams]
  );
  const { items, state, error, handleLoadMore, handleRetry } = useListLoader<
    DetailDrawingListResponse,
    DetailDrawing
  >(paginator, (a, b) => a.id === b.id);

  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();
  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr className='text-left'>
            <th className='pb-3'>Organization</th>
            <th className='pb-3'>Player</th>
            <th className='pb-3'>Session</th>
            <th className='pb-3'>Prompt</th>
            <th className='pb-3'>Thumbnail</th>
          </tr>
        </thead>
        <tbody>
          {items.map((i) => (
            <DrawingItem key={i.id} drawing={i} />
          ))}
        </tbody>
      </table>
      <div>
        {state.isRunning && (
          <div className='flex items-center justify-center mt-50'>
            <Loading />
          </div>
        )}
        {error && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            <ErrorMessage
              text='Something went wrong'
              handleRetry={handleRetry}
            />
          </div>
        )}
        {showEmptyMsg && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            No Drawings.
          </div>
        )}
        {canLoadMore && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>
    </div>
  );
}

export function DrawingList(): JSX.Element {
  const [orgId, setOrgId] = useState<string | null>(null);
  const [streamId, setStreamId] = useState<string | null>(null);
  const sessionId = useMemo(() => {
    if (!streamId) return null;
    try {
      return parseStreamId(streamId).sessionId;
    } catch (error) {
      return null;
    }
  }, [streamId]);

  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <div className='mb-8 flex items-center justify-start gap-4'>
        <div className='flex items-center gap-6'>
          <OrganizationSelect
            orgId={orgId}
            onChange={(org) => setOrgId(org?.id || null)}
            isClearable
          />
        </div>
        <input
          className='field h-10 w-80 mb-0'
          placeholder='Stream/Session ID'
          onChange={(e) => setStreamId(e.target.value)}
        />
      </div>
      <PaginatedList searchParams={{ orgId, sessionId }} />
    </div>
  );
}
