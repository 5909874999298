import {
  type GameLogData,
  type GameLogInfo,
  type GameLogRowRetrieved,
} from './GameLogInfos';

export function linearizeGameLog(data: Partial<GameLogData>) {
  const items: GameLogRowRetrieved<GameLogInfo>[] = [];

  if (!data || !data.indices) return items;

  for (const index of Object.values(data.indices)) {
    const rows = Object.values(index);
    for (const row of rows) {
      items.push(row);
    }
  }

  items.sort((a, b) => a.createdAt - b.createdAt);
  return items;
}
