import { Link } from '@remix-run/react';
import { useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import useSWR from 'swr';

import {
  EnumsSharedAssetPurpose,
  type ModelsSettingsVoiceOverLocales,
} from '@lp-lib/api-service-client/public';

import { type Option } from '../../components/common/Utilities';
import { DeleteIcon } from '../../components/icons/DeleteIcon';
import { NewWindowIcon } from '../../components/icons/NewWindowIcon';
import { Loading } from '../../components/Loading';
import { useSiteI18nSettings } from '../../components/Settings/useSiteI18nSettings';
import { useOpenShareAssetPickerModal } from '../../components/SharedAsset';
import { TTSPreviewButton } from '../../components/VoiceOver/TTSPreviewButton';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { LanguageAllOptions } from '../../i18n/language-options';
import { apiService } from '../../services/api-service';
import { buildReactSelectStyles } from '../../utils/react-select';

type VOLFormData = {
  localesTable: {
    locale: Option<string>;
    voiceId: string;
    previewScript: string;
  }[];
  addLocaleSelector?: Option<string>;
};

function fromIndexedLocales(
  data: ModelsSettingsVoiceOverLocales['voiceOverLocales']
): VOLFormData['localesTable'] {
  return Object.keys(data).map((locale) => {
    return {
      locale: LanguageAllOptions.find((l) => l.value === locale) ?? {
        label: 'Unknown',
        value: locale,
      },
      voiceId: data[locale].voiceId,
      previewScript: data[locale].previewScript,
    };
  });
}

function toIndexedLocales(data: VOLFormData['localesTable']) {
  // convert array to map to kill duplicates
  const reqData: ModelsSettingsVoiceOverLocales['voiceOverLocales'] = {};

  for (const locale of data) {
    reqData[locale.locale.value] = {
      locale: locale.locale.value,
      voiceId: locale.voiceId,
      previewScript: locale.previewScript,
    };
  }

  return reqData;
}

export function VoiceOverLocales() {
  const { data: siteI18n, mutate } = useSiteI18nSettings();
  const serverData = useMemo(
    () => (siteI18n ? fromIndexedLocales(siteI18n) : null),
    [siteI18n]
  );

  return !serverData ? (
    <div className='flex justify-center items-center text-white pt-6'>
      Loading...
    </div>
  ) : (
    <VoiceOverLocalesForm onSaved={() => mutate()} serverData={serverData} />
  );
}

function VoiceOverLocalesForm(props: {
  serverData: VOLFormData['localesTable'];
  onSaved?: () => void;
}) {
  const [editing, setEditing] = useState(false);
  const styles = useMemo(() => buildReactSelectStyles<Option<string>>(), []);

  const { handleSubmit, register, control, getValues, watch } =
    useForm<VOLFormData>({
      values: {
        localesTable: props.serverData ?? [],
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'localesTable',
  });

  const onSubmit = handleSubmit(async (data) => {
    await apiService.settings.updateValue('voice-over-locales', {
      key: 'voiceOverLocales',
      value: toIndexedLocales(data.localesTable),
    });

    props.onSaved?.();

    setEditing(false);
  });

  const sortedFieldsByLabel = useMemo(
    () =>
      fields
        // grab the original index before sorting so the values still align
        .map((field, index) => ({ field, index }))
        // sort by label
        .sort((a, b) =>
          a.field.locale.label.localeCompare(b.field.locale.label)
        ),
    [fields]
  );

  return (
    <div className='px-10 text-white'>
      <h1 className='text-3.5xl font-bold'>Voice Over Locales</h1>

      <p className='pt-4'>
        These mappings will override whichever Voice or TTS Settings are
        configured on the Block or Gamepack levels, if the current user has that
        Locale selected.
      </p>
      <p>
        Since English is the default for our app,{' '}
        <strong>DO NOT MAP ENGLISH</strong> unless you intend to globally
        override all Voices/TTS Settings.
      </p>

      <form onSubmit={onSubmit} className='py-8 flex flex-col gap-4'>
        <fieldset disabled={!editing} className='flex flex-col gap-4'>
          <table className='w-full table-auto border-collapse'>
            <thead>
              <tr>
                <th className='text-left w-1/6'>Label</th>
                <th className='text-left'>Locale</th>
                <th className='text-left w-1/4'>Voice</th>
                <th className='text-left w-1/4'>Demo Script</th>
                <th className='text-left'></th>
              </tr>
            </thead>
            <tbody>
              {sortedFieldsByLabel.map(({ field, index }) => {
                const watchedPreviewScript = watch(
                  `localesTable.${index}.previewScript`
                );

                return (
                  <tr key={field.id}>
                    <td className=''>{field.locale.label}</td>
                    <td className=''>{field.locale.value}</td>
                    <td className='pr-2'>
                      <Controller<VOLFormData, `localesTable.${number}.voiceId`>
                        name={`localesTable.${index}.voiceId`}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <AIHostSettingsEditor
                              value={value}
                              previewScript={watchedPreviewScript}
                              onChange={onChange}
                            />
                          );
                        }}
                      />
                    </td>
                    <td>
                      <label>
                        <textarea
                          className='field m-0 min-h-13.5 py-2'
                          {...register(`localesTable.${index}.previewScript`)}
                          placeholder='Enter preview script...'
                        ></textarea>
                      </label>
                    </td>
                    <td>
                      <div className='w-full flex justify-end'>
                        <button
                          type='button'
                          onClick={() => remove(index)}
                          className='
                            flex-none w-7.5 h-7.5 rounded-lg border border-secondary
                            btn flex justify-center items-center text-red-002 bg-black
                          '
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <label className='font-bold flex gap-1.5 w-full'>
            <div className='flex-grow'>
              <Controller<VOLFormData, 'addLocaleSelector'>
                name='addLocaleSelector'
                control={control}
                render={({ field }) => (
                  <Select<Option<string>>
                    {...field}
                    options={LanguageAllOptions}
                    styles={styles}
                    classNamePrefix='select-box-v2'
                    isSearchable={false}
                    isDisabled={!editing}
                  />
                )}
              />
            </div>
            <button
              type='button'
              className='btn-secondary h-10 w-50'
              onClick={() => {
                const values = getValues();
                console.log(values);
                if (!values.addLocaleSelector) return;

                append({
                  locale: values.addLocaleSelector,
                  voiceId: '',
                  previewScript: 'This is a preview script!',
                });
              }}
            >
              + Add Selected Locale
            </button>
          </label>
        </fieldset>
        {editing ? (
          <button className='btn-primary h-10 w-35' type='submit' key='submit'>
            Save
          </button>
        ) : (
          <button
            type='button'
            className='btn-secondary h-10 w-35'
            onClick={() => setEditing((e) => !e)}
          >
            Edit
          </button>
        )}
      </form>
    </div>
  );
}

function AIHostSettingsEditor(props: {
  value?: string | null;
  previewScript: string;
  onChange: (value: string | null | undefined) => void;
}) {
  const openShareAssetPickerModal = useOpenShareAssetPickerModal();

  const { data, isLoading, error } = useSWR(
    !props.value ? null : ['/media/shared/', props.value],
    async ([_, id]) => {
      const sharedAsset = await apiService.media.getSharedAsset(id);
      return sharedAsset.data.sharedAsset;
    }
  );

  const handleAdd = useLiveCallback(() => {
    openShareAssetPickerModal({
      purposes: [EnumsSharedAssetPurpose.SharedAssetPurposeVoice],
      onSelected: (item) => {
        props.onChange(item.id);
      },
    });
  });

  return (
    <div className='flex flex-col gap-4'>
      <div className='w-full'>
        {!props.value ? (
          <button
            type='button'
            className='mt-2 btn text-sms text-secondary'
            onClick={handleAdd}
          >
            + Add Voice
          </button>
        ) : isLoading ? (
          <Loading text='' />
        ) : error || !data ? (
          <div className='text-red-002 text-sms text-center'>
            Error loading voice
          </div>
        ) : (
          <div className='w-full flex items-center gap-2'>
            <div className='w-full bg-layer-002 rounded border border-secondary px-4 py-2 flex items-center justify-between'>
              <div className='flex gap-2'>
                <div className='text-white font-bold'>{data.label}</div>
                {props.value && (
                  <button
                    type='button'
                    className='btn text-sms text-icon-gray hover:text-primary hover:underline transition-all'
                    onClick={handleAdd}
                  >
                    Edit
                  </button>
                )}
              </div>
              <Link
                to={`/admin/toolkit/shared-media?id=${props.value}`}
                target='_blank'
              >
                <div className='transform hover:scale-110 transition-transform'>
                  <NewWindowIcon />
                </div>
              </Link>
            </div>
            <div className='flex-none'>
              <TTSPreviewButton
                script={props.previewScript}
                settings={data.data?.ttsRenderSettings}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
