export type ParsedStreamId = {
  venueId: string | null;
  sessionId: string;
};

export function parseStreamId(streamId: string): ParsedStreamId {
  if (streamId.length === 36) {
    return { venueId: null, sessionId: streamId };
  }
  const segments = streamId.split('/');
  if (
    segments.length !== 3 ||
    segments[0].length !== 36 ||
    segments[2].length !== 36
  ) {
    throw new Error('Invalid StreamId');
  }
  return { venueId: segments[0], sessionId: segments[2] };
}
