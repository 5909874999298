import { useCallback, useEffect, useRef } from 'react';
import {
  DndProvider,
  type DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { proxy, useSnapshot } from 'valtio';

import { BlockType } from '@lp-lib/game';

import { useAsyncCall } from '../../../../hooks/useAsyncCall';
import { useInstance } from '../../../../hooks/useInstance';
import { apiService } from '../../../../services/api-service';
import {
  type BlockSettings,
  type SequenceConfig,
  type TeamRelayBlockSettings,
  type TeamRelayLevel,
} from '../../../../types/block';
import { err2s } from '../../../../utils/common';
import { canMove } from '../../../../utils/dnd';
import { ValtioUtils } from '../../../../utils/valtio';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { MenuIcon } from '../../../icons/MenuIcon';
import { Loading } from '../../../Loading';
import { MAX_ALLOWED_COLUMNS } from './types';

interface TeamRelayBlockSettingsControl {
  addLevel: () => void;
  deleteLevel: (idx: number) => void;
  addConfig: (levelIdx: number) => void;
  deleteConfig: (levelIdx: number, configIdx: number) => void;
  updateConfig: (
    levelIdx: number,
    configIdx: number,
    config: Partial<SequenceConfig>
  ) => void;
  move: (from: number, to: number) => void;
}

function Config(props: {
  levelIdx: number;
  idx: number;
  config: SequenceConfig;
  api: TeamRelayBlockSettingsControl;
}): JSX.Element {
  const { levelIdx, idx, api } = props;
  return (
    <div className='border border-secondary m-2 p-2 text-2xs relative group'>
      <div className='text-xs mb-2'>Sequence {props.idx + 1}:</div>
      <label className='flex flex-row my-px'>
        <div className='w-30'>
          <span className='text-white'>Direction:</span>
        </div>
        <select
          className='bg-transparent text-gray-50 outline-none w-20'
          value={props.config.direction}
          onChange={(e) =>
            api.updateConfig(levelIdx, idx, {
              direction: e.target.value as never,
            })
          }
        >
          <option className='bg-lp-black-001 text-gray-50' value='forward'>
            forward
          </option>
          <option className='bg-lp-black-001 text-gray-50' value='backward'>
            backward
          </option>
        </select>
      </label>
      <label className='flex flex-row my-px'>
        <div className='w-30'>
          <span className='text-white'>Keys:</span>
        </div>
        <select
          className='bg-transparent text-gray-50 outline-none w-20'
          value={props.config.keys}
          onChange={(e) =>
            api.updateConfig(levelIdx, idx, {
              keys: e.target.value as never,
            })
          }
        >
          <option className='bg-lp-black-001 text-gray-50' value='numbers'>
            numbers
          </option>
          <option className='bg-lp-black-001 text-gray-50' value='letters'>
            letters
          </option>
        </select>
      </label>
      <label className='flex flex-row my-px'>
        <div className='w-30'>
          <span className='text-white'>Max Columns:</span>
        </div>
        <div className='w-20'>
          <input
            className='field w-full h-4 text-2xs rounded-sm p-1 m-0'
            value={props.config.maxColumns}
            type='number'
            onChange={(e) => {
              api.updateConfig(levelIdx, idx, {
                maxColumns: Math.min(
                  Math.max(parseInt(e.target.value), 0),
                  MAX_ALLOWED_COLUMNS
                ),
              });
            }}
          />
        </div>
      </label>
      <label className='flex flex-row my-px'>
        <div className='w-30'>
          <span className='text-white'>Extended Nodes:</span>
        </div>
        <div className='w-20'>
          <input
            className='field w-full h-4 text-2xs rounded-sm p-1 m-0'
            value={props.config.numOfHoldNodes}
            type='number'
            onChange={(e) =>
              api.updateConfig(levelIdx, idx, {
                numOfHoldNodes: Math.min(
                  Math.max(parseInt(e.target.value), 0),
                  8
                ),
              })
            }
          />
        </div>
      </label>
      <button
        className='btn-delete w-6 h-6 hidden items-center justify-center 
      rounded-md absolute -top-2 -right-2 group-hover:flex'
        onClick={() => api.deleteConfig(levelIdx, idx)}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

function Level(props: {
  idx: number;
  level: TeamRelayLevel;
  api: TeamRelayBlockSettingsControl;
}): JSX.Element {
  const { idx, level, api } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'team-relay-level',
    hover(item: { from: number }, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const dragIndex = item.from;
      const hoverIndex = idx;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (canMove(dragIndex, hoverIndex, hoverBoundingRect, monitor)) {
        api.move(dragIndex, hoverIndex);
        item.from = hoverIndex;
      }
    },
  });
  const [collected, drag, drapPreview] = useDrag({
    type: 'team-relay-level',
    item: () => {
      return { from: idx };
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 'opacity-40' : '',
    }),
  });

  drapPreview(drop(ref));
  return (
    <div className={`my-2 ${collected.opacity}`} ref={ref}>
      <div className='flex items-center group'>
        <button ref={drag} className='btn cursor-move mr-1'>
          <MenuIcon />
        </button>
        <div>Level {props.idx + 1}</div>
        <div className='hidden group-hover:flex'>
          <button
            className='w-24 h-5 btn-secondary text-2xs mx-1'
            onClick={() => api.addConfig(idx)}
          >
            New Sequence
          </button>
          <button
            className='w-24 h-5 btn-delete text-2xs mx-1'
            onClick={() => api.deleteLevel(idx)}
          >
            Delete Level
          </button>
        </div>
      </div>
      <div className='grid grid-cols-5'>
        {level.configs.map((c, i) => (
          <Config key={i} levelIdx={idx} idx={i} config={c} api={api} />
        ))}
      </div>
      {level.configs.length === 0 && (
        <div className='text-xs'>No Sequences</div>
      )}
    </div>
  );
}

export function TeamRelayAdminPanel(): JSX.Element {
  const settings = useInstance(() => proxy<BlockSettings>({ levels: [] }));
  const snap = useSnapshot(settings) as typeof settings;
  const api: TeamRelayBlockSettingsControl = {
    addLevel: useCallback(() => {
      settings.levels.push({
        configs: [],
      });
    }, [settings]),
    deleteLevel: useCallback(
      (idx: number) => {
        settings.levels.splice(idx, 1);
      },
      [settings]
    ),
    addConfig: useCallback(
      (levelIdx: number) => {
        settings.levels[levelIdx].configs.push({
          direction: 'forward',
          keys: 'numbers',
          maxColumns: 8,
          numOfHoldNodes: 0,
        });
      },
      [settings]
    ),
    deleteConfig: useCallback(
      (levelIdx: number, configIdx: number) => {
        settings.levels[levelIdx].configs.splice(configIdx, 1);
      },
      [settings]
    ),
    updateConfig: useCallback(
      (
        levelIdx: number,
        configIdx: number,
        config: Partial<SequenceConfig>
      ) => {
        ValtioUtils.update(
          settings.levels[levelIdx].configs[configIdx],
          config
        );
      },
      [settings]
    ),
    move: useCallback(
      (from: number, to: number) => {
        const level = settings.levels[from];
        settings.levels.splice(from, 1);
        settings.levels.splice(to, 0, level);
      },
      [settings]
    ),
  };

  const {
    state: { transformed: loadStateTransformed },
    error: loadError,
    call: load,
  } = useAsyncCall(
    useCallback(async () => {
      const resp =
        await apiService.block.getBlockSettings<TeamRelayBlockSettings>(
          BlockType.TEAM_RELAY
        );
      if (resp.data) settings.levels = resp.data.levels;
    }, [settings])
  );

  const {
    state: { transformed: saveStateTransformed },
    error: saveError,
    call: save,
  } = useAsyncCall(
    useCallback(async () => {
      await apiService.block.updateBlockSettings<TeamRelayBlockSettings>(
        BlockType.TEAM_RELAY,
        settings
      );
    }, [settings])
  );

  useEffect(() => {
    load();
  }, [load]);

  if (loadStateTransformed.isRunning) return <Loading />;
  if (loadError)
    return (
      <div className='text-red-002'>
        Fail to load levels: {err2s(loadError)}
      </div>
    );

  return (
    <div>
      <div className='flex'>
        <button
          className='w-30 h-8 btn-secondary text-sm mx-1'
          onClick={api.addLevel}
        >
          New Level
        </button>
        <button className='w-30 h-8 btn-primary text-sm mx-1' onClick={save}>
          <div className='flex flex-row items-center justify-center'>
            <p>Save</p>
            {saveStateTransformed.isRunning && <Loading text='' />}
          </div>
        </button>
        {saveError && (
          <p className='text-red-002 text-2sx'>{err2s(saveError)}</p>
        )}
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className='my-4'>
          {snap.levels.map((l, i) => (
            <Level key={i} idx={i} level={l} api={api} />
          ))}
        </div>
      </DndProvider>
    </div>
  );
}
