import useSWR from 'swr';

import { Loading } from '../../components/Loading';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';

function Connections(): JSX.Element {
  const {
    data: connections,
    error,
    isValidating,
    mutate,
  } = useSWR(`/slack/socket-mode-connections`, async () => {
    const resp = await apiService.slack.getSocketModeConnections();
    return resp.data.connections;
  });
  if (isValidating) return <Loading />;
  if (error) return <div className='text-red-002'>{err2s(error)}</div>;
  if (!connections?.length)
    return <div className='text-white'>No Connections Found</div>;

  const handleClick = async () => {
    await apiService.slack.updateSocketModeConnections();
    mutate();
  };

  return (
    <div>
      <button className='btn-primary w-30 h-8' onClick={handleClick}>
        Take Over
      </button>
      <table className='my-10'>
        <thead>
          <tr className='font-bold'>
            <td className='w-20'>Env</td>
            <td className='w-20'>Connected</td>
          </tr>
        </thead>
        <tbody className='text-sm'>
          {connections.map((c, index) => (
            <tr key={index}>
              <td>{c.env}</td>
              <td>{`${c.connected}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function SlackSocketModeConnections(): JSX.Element {
  return (
    <div className='w-full h-full text-white px-20'>
      <div className='text-2xl mb-8'>Slack Events Utility</div>
      <Connections />
    </div>
  );
}
