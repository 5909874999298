import { Link } from '@remix-run/react';
import { useParams } from '@remix-run/react';
import { useState } from 'react';
import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import { type Message } from '../../types';
import { CampaignMessages } from '../MessageLogic/message';

export const MessageCampaignMessageList = (): JSX.Element => {
  const { id } = useParams<'id'>();

  const [messages, setMessages] = useState<Message[]>([]);

  const { data } = useSWR(`/message-campaign/${id}`, async () => {
    const resp = await apiService.messageCampaign.getCampaignById(id || '');
    return resp.data.campaign;
  });

  return (
    <div className='w-full h-full text-white px-20'>
      {data && (
        <div className='text-2xl font-medium text-icon-gray flex justify-between mb-7 mr-10'>
          <div>
            <Link to={`/admin/toolkit/message-campaigns`}>
              Message Campaigns
            </Link>{' '}
            \{' '}
            <Link to={`/admin/toolkit/message-campaigns/${id}`}>
              {data.name}
            </Link>{' '}
            \ <span className='text-white'>Message Sent</span>
          </div>
        </div>
      )}

      {data && (
        <div>
          <h1 className='pb-5 text-2xl'>{data.name}</h1>
          <h1 className='pb-5 text-base'>{`Messsage Sent: ${messages.length}`}</h1>
        </div>
      )}

      <CampaignMessages
        params={{
          campaignId: data?.id,
        }}
        onMessagesLoad={(messages) => {
          setMessages(messages);
        }}
      />
    </div>
  );
};
