import { useMemo } from 'react';

import { type Action, ActionSheet } from '../ActionSheet';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';

type Keys = 'editTemplate' | 'deleteTemplate';

export function MessageTemplateActionSheet(props: {
  onDelete: () => void;
  onEdit: () => void;
}): JSX.Element {
  const { onEdit, onDelete } = props;
  const actions: Action<Keys>[] = useMemo(
    () => [
      {
        kind: 'button',
        key: 'editTemplate',
        icon: <EditIcon />,
        text: 'Edit',
        onClick: onEdit,
      },
      {
        kind: 'button',
        key: 'deleteTemplate',
        icon: <DeleteIcon />,
        text: 'Delete',
        onClick: onDelete,
        className: 'disabled text-red-002',
      },
    ],
    [onEdit, onDelete]
  );
  return <ActionSheet<Keys> actions={actions} placement='left' />;
}
