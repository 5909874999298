import { useSearchParams } from '@remix-run/react';
import copy from 'copy-to-clipboard';
import { useMemo } from 'react';
import React from 'react';
import { Waypoint } from 'react-waypoint';

import { makeAnonGamePackTagUrl } from '../../../app/components/GamePack/utils';
import { useListLoader } from '../../hooks/useListLoader';
import { apiService, type TagListResponse } from '../../services/api-service';
import { SiteBaseURL } from '../../services/public';
import { type Tag, TagUtils } from '../../types';
import { type Action, ActionSheet } from '../ActionSheet';
import { ErrorMessage } from '../Game/GameCenter';
import { CopyIcon } from '../icons/CopyIcon';
import { Loading } from '../Loading';
import { useTriggerTagEditorModal } from './TagEditor';

export function TagMenu(props: { tag: Tag }) {
  const { tag } = props;

  const actions: Action<string>[] = [];
  actions.push({
    kind: 'button',
    key: 'copy-public-link',
    text: 'Copy: Public Link',
    icon: <CopyIcon />,
    onClick: () => {
      const url = new SiteBaseURL();
      url.pathname = makeAnonGamePackTagUrl(tag);
      copy(url.toString());
    },
  });

  return <ActionSheet btnSizingClassName='w-7.5 h-7.5' actions={actions} />;
}

function TagItem(props: {
  tag: Tag;
  onClick: (tag: Tag) => void;
  menu: React.ReactNode;
}) {
  const { tag, onClick, menu } = props;
  const extensions = (tag.extensions ?? [])
    .filter((e) => e.objectsCount > 0)
    .sort((a, b) => a.objectType - b.objectType);
  return (
    <tr className='text-left border-b border-secondary'>
      <td className='py-4'>
        <button
          type='button'
          className='btn text-primary underline'
          onClick={() => onClick(tag)}
        >
          {tag.name}
        </button>
      </td>
      <td className='text-xs py-4'>
        {extensions.length > 0 ? (
          <ul className='list-none'>
            {extensions.map((e) => (
              <li key={e.objectType}>
                {TagUtils.getObjectLabel(e.objectType, true)}: {e.objectsCount}
              </li>
            ))}
          </ul>
        ) : (
          'N/A'
        )}
      </td>
      <td>{menu}</td>
    </tr>
  );
}

function PaginatedList(props: { q?: string | null }): JSX.Element | null {
  const { q } = props;
  const paginator = useMemo(() => apiService.tag.search(q), [q]);

  const { items, dao, state, error, handleLoadMore, handleRetry } =
    useListLoader<TagListResponse, Tag>(paginator, (a, b) => a.id === b.id);

  const triggerTagEditorModal = useTriggerTagEditorModal();

  const onClick = (tag: Tag) => {
    triggerTagEditorModal({
      tag,
      onSave: (updated) => dao.updateItem(updated),
    });
  };

  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();
  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr className='text-left'>
            <th className='pb-3 w-1/4'>Category</th>
            <th className='pb-3'>Used In</th>
            <th className='pb-3 w-10' />
          </tr>
        </thead>
        <tbody>
          {items.map((tag) => (
            <TagItem
              key={tag.id}
              tag={tag}
              onClick={onClick}
              menu={<TagMenu tag={tag} />}
            />
          ))}
        </tbody>
      </table>
      <div>
        {state.isRunning && (
          <div className='flex items-center justify-center mt-50'>
            <Loading />
          </div>
        )}
        {error && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            <ErrorMessage
              text='Something went wrong'
              handleRetry={handleRetry}
            />
          </div>
        )}
        {showEmptyMsg && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            No Categories.
          </div>
        )}
        {canLoadMore && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>
    </div>
  );
}

export function AdminTagList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get('q');
  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <div className='mb-8 flex items-center justify-start gap-4'>
        <input
          className='field h-10 w-80 mb-0'
          placeholder='Search'
          onChange={(e) =>
            setSearchParams((prev) => ({ ...prev, q: e.target.value }))
          }
          value={q || ''}
        />
      </div>
      <PaginatedList q={q} />
    </div>
  );
}
