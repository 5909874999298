import { buildDiscoverUrl } from '@clickandmortar/kibana-url-builder';
import {
  type KibanaQueryFilter,
  type KibanaQueryPeriod,
} from '@clickandmortar/kibana-url-builder/dist/types';

import config from '../config';

type KibanaLinkOptions =
  | (
      | {
          dest: 'dashboard';
          id: string;
        }
      | { dest: 'discover'; index: string }
    ) & {
      query:
        | {
            kql: string;
          }
        | { lucene: string };
      filters?: KibanaQueryFilter[];
      period?: KibanaQueryPeriod;
    };

export function kibanaUrl(
  options: KibanaLinkOptions,
  baseUrl = config.kibana.baseUrl
): string {
  const luceneQuery = 'lucene' in options.query ? options.query.lucene : '';
  let url = buildDiscoverUrl({
    host: baseUrl,
    query: luceneQuery,
    filters: options.filters ?? [],
    index: options.dest === 'discover' ? options.index : undefined,
    sort: { field: 'createdAt', direction: 'asc' },
    columns: ['msg', 'payload'],
    period: options.period,
  });

  if ('kql' in options.query) {
    url = url.replace(
      `language:lucene,query:''`,
      `language:kuery,query:'${encodeURIComponent(options.query.kql)}'`
    );
  }

  switch (options.dest) {
    case 'discover':
      break;
    case 'dashboard': {
      // https://kibana.internal.gadder.live/app/kibana#/discover?[...]

      // Unfortunately, the "query" is actually part of the hash, so we cannot
      // use normal URL parsing!
      url = url.replace(
        /^[^?]+\?/,
        `${baseUrl}/app/dashboards#/view/${options.id}?`
      );
    }
  }

  return url;
}

export function kibanaPeriodPlusMinus5Minutes(
  startedAt: number | undefined | null,
  endedAt: number | undefined | null
): KibanaQueryPeriod | null {
  if (!startedAt) return null;
  const from = new Date(startedAt);
  const to = endedAt ? new Date(endedAt) : new Date();
  from.setMinutes(from.getMinutes() - 5);
  to.setMinutes(to.getMinutes() + 5);
  return {
    mode: 'absolute',
    from: from.toISOString(),
    to: to.toISOString(),
  };
}
