import useSWR from 'swr';

import { Loading } from '../../components/Loading';
import { apiService } from '../../services/api-service';
import {
  type DmgTemplateResponse,
  type DmgTemplatesResponse,
} from '../../services/api-service/dmg.api';

async function getTemplates(): Promise<DmgTemplatesResponse> {
  const resp = await apiService.dmg.getTemplates();
  return resp.data;
}

function TemplateRow(props: DmgTemplateResponse): JSX.Element {
  return (
    <div className='w-full bg-lp-gray-001 rounded-xl flex items-center'>
      <div className='w-1/8 h-full flex-none text-white font-bold text-md px-8 py-10'>
        {props.displayName}
      </div>
      <div className='flex-grow h-64 p-4 flex justify-center'>
        <img
          className='object-contain w-full h-full'
          src={props.exampleImageUrl}
          alt='Preview'
        />
      </div>
    </div>
  );
}

export function DmgTemplates(): JSX.Element {
  const { data, error, isValidating } = useSWR('/dmg/templates', getTemplates, {
    shouldRetryOnError: false,
  });

  if (!data && !error && isValidating) {
    return <Loading />;
  }

  if (error || !data) {
    return (
      <div className='w-full flex justify-center text-white text-md font-white'>
        Whoops! Failed to load DMG templates. Refresh...
      </div>
    );
  }

  return (
    <div className='w-full h-full px-10'>
      <div className='text-2xl font-medium text-white mb-4'>
        Dynamic Media Templates
      </div>

      <div className='w-4/5 mx-auto flex flex-col gap-4'>
        {(data.templates ?? []).map((template) => (
          <TemplateRow key={template.id} {...template} />
        ))}
      </div>
    </div>
  );
}
