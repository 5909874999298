import { Link } from '@remix-run/react';
import { useNavigate, useParams } from '@remix-run/react';
import { format } from 'date-fns';
import { useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import {
  type DetailedMessageCampaign,
  MessageCampaignType,
} from '../../services/api-service/messageCampaign.api';
import { type UpdateMessageLogicRequest } from '../../services/api-service/messageLogic.api';
import { err2s } from '../../utils/common';
import { ArrowRightIcon } from '../icons/Arrows';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { EditIcon } from '../icons/EditIcon';
import { Loading } from '../Loading';
import { MessageLogicCards } from '../MessageLogic';
export interface MessageCampaignFormData {
  id: string;
  name: string;
}

const IntegrationList = (props: {
  campaign: DetailedMessageCampaign;
}): JSX.Element => {
  const { campaign } = props;
  return (
    <ul className='list-disc pl-5'>
      {campaign.messageCampaignType === MessageCampaignType.GlobalRound &&
        campaign.globalPairingRounds &&
        campaign.globalPairingRounds.slice(0, 3).map((round) => {
          return (
            <li className='hover:text-blue-004'>
              <Link
                to={`/admin/programs/global-pairing-rounds/${round.id}/edit`}
              >
                {`${round.mainGamePack?.name} - ${format(
                  new Date(round.startedAt),
                  'eee, MMM do yyyy'
                )} - ${format(new Date(round.endedAt), 'eee, MMM do yyyy')}`}
              </Link>
            </li>
          );
        })}
      {campaign.globalPairingRounds &&
        campaign.globalPairingRounds.length > 3 && (
          <li className='hover:text-blue-004'>
            <Link to={`/admin/programs/global-pairing-rounds/list`}>
              {`(see all)`}
            </Link>
          </li>
        )}
    </ul>
  );
};

const CampaignNameField = (): JSX.Element => {
  const { control } = useFormContext<DetailedMessageCampaign>();

  return (
    <Controller
      control={control}
      name='name'
      render={({ field: { onChange, value } }) => {
        return <CampaignNameEditor value={value} onChange={onChange} />;
      }}
    />
  );
};

const CampaignNameEditor = (props: {
  value: string;
  onChange: () => void;
}): JSX.Element => {
  const { value, onChange } = props;
  const [editing, setEditing] = useState<boolean>(false);

  return (
    <div className='flex flex-row'>
      {editing ? (
        <input
          type='text'
          value={value}
          onChange={onChange}
          className='w-140 border border-secondary rounded-xl text-2xl font-medium bg-black focus:outline-none mr-2'
        />
      ) : (
        <div className='text-2xl font-medium mr-2 uppercase'> {value}</div>
      )}
      <button
        type='button'
        className='focus:outline-none'
        onClick={() => setEditing(!editing)}
      >
        {editing ? <FilledCheckIcon /> : <EditIcon />}
      </button>
    </div>
  );
};

const Header = (props: {
  onCancel: () => void;
  isSubmitting: boolean;
  error: Error | null;
  messageCampaign: DetailedMessageCampaign;
}): JSX.Element => {
  const { onCancel, isSubmitting, messageCampaign, error } = props;

  const [showIntegrations, setShowIntegrations] = useState<boolean>(false);

  const isGlobalRound =
    messageCampaign?.messageCampaignType === MessageCampaignType.GlobalRound;

  if (!messageCampaign) {
    return <Loading />;
  }
  return (
    <header className='flex justify-between'>
      <div>
        <CampaignNameField />
        <div className='text-base font-medium'>{`Trigger (T0): ${messageCampaign?.triggerDescription}`}</div>
        <div className='text-base font-medium flex'>
          <div>{`Integrations: ${
            isGlobalRound ? messageCampaign.integrationCount : 'N/A'
          }`}</div>
          {isGlobalRound && (
            <button
              className='w-7.5 h-7.5 relative rounded-lg appearance-none outline-none focus:outline-none  flex justify-center items-center text-white'
              type='button'
              onClick={() => setShowIntegrations(!showIntegrations)}
            >
              <ArrowRightIcon
                className={`w-4 h-4 fill-current transform ${
                  showIntegrations ? '-rotate-90' : 'rotate-90'
                }`}
              />
            </button>
          )}
        </div>
        {showIntegrations && messageCampaign && (
          <IntegrationList campaign={messageCampaign} />
        )}
      </div>

      <div className='flex flex-col gap-1'>
        <div className='flex justify-center items-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-40 h-10'
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving' : 'Save'}
          </button>
        </div>
        {error && (
          <div className='text-sms font-normal text-red-002'>
            {err2s(error)}
          </div>
        )}
      </div>
    </header>
  );
};

export const MessageCampaignEditor = (): JSX.Element => {
  const { id } = useParams<'id'>();

  const { data } = useSWR(`/message-campaign/${id}`, async () => {
    const resp = await apiService.messageCampaign.getCampaignById(id || '');
    return resp.data.campaign;
  });

  if (!data) {
    return <Loading />;
  }

  return <MessageCampaignForm campaign={data} />;
};

export const MessageCampaignForm = (props: {
  campaign: DetailedMessageCampaign;
}): JSX.Element => {
  const { campaign } = props;

  const navigate = useNavigate();

  const formReturned = useForm<MessageCampaignFormData>({
    defaultValues: {
      id: campaign.id,
      name: campaign.name,
    },
  });

  const updatedMessageLogicsRef = useRef<UpdateMessageLogicRequest[]>([]);
  const onSubmit = formReturned.handleSubmit(
    async (data: MessageCampaignFormData) => {
      try {
        await apiService.messageCampaign.update(data.id, data);
        if (updatedMessageLogicsRef.current.length > 0) {
          await Promise.all(
            [...new Set(updatedMessageLogicsRef.current)].map(
              (req: UpdateMessageLogicRequest) =>
                apiService.messageLogic.update(req)
            )
          );
        }
        navigate('/admin/toolkit/message-campaigns/list');
      } catch (error) {
        console.error(error);
      }
    }
  );

  return (
    <div>
      <FormProvider {...formReturned}>
        <form className='w-full px-10 text-white' onSubmit={onSubmit}>
          <Header
            onCancel={() => navigate(-1)}
            isSubmitting={false}
            error={null}
            messageCampaign={campaign}
          />
        </form>
      </FormProvider>
      <div className='flex flex-col mt-9 text-white pl-20'>
        <h1 className='pb-5 text-2xl'>Messages</h1>
        <MessageLogicCards
          campaignId={campaign.id}
          parentId={null}
          editable={true}
          onMessageTemplateChange={(data) => {
            updatedMessageLogicsRef.current.push(data);
          }}
          checkLogicDisabled={() => false}
        />
      </div>
    </div>
  );
};
