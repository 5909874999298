import { ErrorMessage } from '@hookform/error-message';
import { Link, useSearchParams } from '@remix-run/react';
import copy from 'copy-to-clipboard';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoGetSessionSentryStatsResponse,
  type DtoSessionVoiceOverAudit,
} from '@lp-lib/api-service-client/public';

import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../components/ConfirmCancelModalContext';
import { linearizeGameLog } from '../../components/GameLog/GameLogUtils';
import { ArrowDownIcon, ArrowRightIcon } from '../../components/icons/Arrows';
import { CopyIcon } from '../../components/icons/CopyIcon';
import { Loading } from '../../components/Loading';
import {
  type ParsedStreamId,
  parseStreamId,
  SessionTrackTable,
} from '../../components/Session';
import config from '../../config';
import { getEnv } from '../../config/getEnv';
import { sentry } from '../../config/sentry';
import { useCopyTextLink } from '../../hooks/useCopyTextLink';
import {
  apiService,
  type GetVenueSessionSnapshotResponse,
} from '../../services/api-service';
import { APIServiceURL, publicFetchAPIService } from '../../services/public';
import { WebRTCUtils } from '../../services/webrtc';
import {
  type Organization,
  type Organizer,
  type SessionTrack,
} from '../../types';
import { type Team, type TeamId } from '../../types/team';
import { ClientType, type Participant, type User } from '../../types/user';
import { err2s } from '../../utils/common';
import { getToken } from '../../utils/getToken';
import { kibanaPeriodPlusMinus5Minutes, kibanaUrl } from '../../utils/kibana';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

type FormData = {
  streamId: string;
};

type SnapshotData = GetVenueSessionSnapshotResponse['data'];

interface ParticipantEx extends Participant {
  teamJoinedAt: number;
}

const formatter = new Intl.DateTimeFormat('en-US', {
  hourCycle: 'h24',
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  fractionalSecondDigits: 3,
});

function linkForAgoraAnalytics(channel: string): string {
  return `https://console.agora.io/analytics/call/search?cname=${channel}`;
}

function KibanaDiscoverLink(props: {
  className?: string;
  startedAt: number | undefined | null;
  endedAt: number | undefined | null;
  kql: string;
  children: ReactNode;
}) {
  const period = kibanaPeriodPlusMinus5Minutes(props.startedAt, props.endedAt);
  if (!period) return null;

  const href = kibanaUrl({
    dest: 'discover',
    index: config.kibana.webAppLogIndex,
    query: {
      kql: props.kql,
    },
    filters: [
      {
        type: 'phrases',
        field: 'scope',
        value: ['firebase-latency', 'ond-game-controller-heartbeat'],
        negate: true,
        disabled: false,
      },
    ],
    period,
  });

  return (
    <a href={href} className={props.className ?? ''}>
      {props.children}
    </a>
  );
}

function GameLogTable(props: {
  organizerLookup: OrganizerLookup | null;
  snapshot: SnapshotData;
  sessionTrack: SessionTrack;
}): ReactNode {
  const linear = useMemo(
    () => linearizeGameLog(props.snapshot['game-log']),
    [props.snapshot]
  );

  let previousGroup: 'Before Game' | 'After Game' | 'During Game' =
    'Before Game';

  const [groupsOpen, setGroupsOpen] = useState(() => ({
    'Before Game': false,
    'During Game': true,
    'After Game': false,
  }));

  if (linear.length === 0) return null;

  return (
    <details>
      <summary className='text-xl font-bold pb-4'>Venue Game Log</summary>
      <table
        className='
        table-auto border-collapse border border-slate-400 w-full text-center text-sm
        h-1
      '
      >
        <thead>
          <tr>
            <th className=''>Group</th>
            <th className='border border-slate-300'>Time</th>
            <th className='border border-slate-300'>Kind</th>
            <th className='border border-slate-300'>Extra</th>
            <th className='border border-slate-300'>User</th>
            <th className='border border-slate-300'>Info</th>
          </tr>
        </thead>
        <tbody>
          {linear.map((item, idx) => {
            const participant = item.info.clientId
              ? getParticipantByClientId(props.snapshot, item.info.clientId)
              : null;
            const name = formatNameforParticipant(
              participant,
              props.organizerLookup
            );
            const id = `${item.createdAt}-${idx}`;

            const isDifferentSession =
              item.info.sessionId &&
              props.sessionTrack.id !== item.info.sessionId;
            const isBeforeGameStart =
              item.createdAt < (props.snapshot.session?.firstStartedAt ?? 0);
            const isAfterGameEnd =
              (props.snapshot.session?.endedAt ?? 0) > 0 &&
              item.createdAt > (props.snapshot.session?.endedAt ?? 0);
            const gracePeriodMs = 15 * 60 * 1000;
            const isBeforeGameStartGracePeriod =
              item.createdAt <
              (props.snapshot.session?.firstStartedAt ?? 0) - gracePeriodMs;
            const isAfterGameEndGracePeriod =
              (props.snapshot.session?.endedAt ?? 0) > 0 &&
              item.createdAt >
                (props.snapshot.session?.endedAt ?? 0) + gracePeriodMs;

            const currentGroup = isBeforeGameStartGracePeriod
              ? 'Before Game'
              : isAfterGameEndGracePeriod
              ? 'After Game'
              : isDifferentSession && isBeforeGameStart
              ? 'Before Game'
              : isDifferentSession && isAfterGameEnd
              ? 'After Game'
              : 'During Game';
            const showButton = previousGroup !== currentGroup || idx === 0;
            previousGroup = currentGroup;
            const groupOpen = groupsOpen[currentGroup];

            if (
              item.info.kind === 'scoreboard-shown' &&
              !!item.info.scoreboard
            ) {
              // Truncate this to be more succinct visually
              item.info.scoreboard.forEach((score) => {
                delete score.orgLogo;
              });
            }

            const stringified = JSON.stringify(item.info, null, 2);

            return (
              <tr
                key={id}
                className={`h-full ${
                  groupOpen || showButton ? 'table-row' : 'hidden'
                }`}
              >
                <td className='border border-slate-300 p-2'>
                  {showButton ? (
                    <button
                      title="Toggle group's visibility"
                      className='flex items-center gap-2'
                      type='button'
                      onClick={() =>
                        setGroupsOpen((g) => ({
                          ...g,
                          [currentGroup]: !g[currentGroup],
                        }))
                      }
                    >
                      {groupOpen ? (
                        // These are opposite/against our site's conventions,
                        // but matches how <details> handles it, which is used
                        // extensively on this page.
                        <ArrowDownIcon />
                      ) : (
                        <ArrowRightIcon />
                      )}{' '}
                      {currentGroup}
                    </button>
                  ) : null}
                </td>
                <td className='border border-slate-300 flex flex-col gap-1 justify-center h-full'>
                  <span>{formatter.format(item.createdAt)}</span>
                  <pre className='bg-secondary px-2 text-2xs'>
                    {new Date(item.createdAt).toISOString()}
                  </pre>
                  <pre className='bg-secondary px-2 text-2xs'>
                    {item.createdAt}
                  </pre>
                </td>
                <td className='border border-slate-300 text-left'>
                  <div className='p-2'>{item.info.kind}</div>
                </td>
                <td className='border border-slate-300 text-left'>
                  <div className='p-2 flex flex-col gap-0.5'>
                    {isDifferentSession && (
                      <span className='text-red-500'> (Different Session)</span>
                    )}
                    {isBeforeGameStart && (
                      <span className='text-secondary'>
                        {' '}
                        (Before Game Start)
                      </span>
                    )}
                    {isAfterGameEnd && (
                      <span className='text-secondary'> (After Game End)</span>
                    )}
                  </div>
                </td>
                <td className='border border-slate-300'>{name}</td>
                <td className='border border-slate-300 text-left relative'>
                  <CopyText text={stringified} />
                  <pre className='bg-secondary px-2 text-2xs whitespace-pre-wrap'>
                    {item.info.kind === 'scoreboard-shown' ? (
                      <details>
                        <summary>Expand</summary>
                        {stringified}
                      </details>
                    ) : (
                      stringified
                    )}
                  </pre>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </details>
  );
}

function CopyText(props: { text: string }) {
  const [onExecuteCopy, label] = useCopyTextLink({
    label: (
      <>
        <CopyIcon className='w-3 h-3 fill-current' />
      </>
    ),
    getter: () => props.text,
  });

  return (
    <button
      type='button'
      className={`
        absolute
        right-0 top-0
        btn btn-secondary text-xs
        bg-transparent
        px-4 py-2
        flex items-center gap-1
      `}
      onClick={() => onExecuteCopy()}
    >
      {label}
    </button>
  );
}

function VoiceOverAuditsTable(props: {
  voiceOverAudits: DtoSessionVoiceOverAudit[];
}): JSX.Element | null {
  if (props.voiceOverAudits.length === 0) return null;

  return (
    <details id='voice-over-audits'>
      <summary className='text-xl font-bold pb-4'>Voice Over Audits</summary>

      <table className='table-auto border-collapse border border-slate-400 w-full text-center text-sm'>
        <thead>
          <tr>
            <th className='border border-slate-300'>Block Id</th>
            <th className='border border-slate-300'>Initiated At</th>
            <th className='border border-slate-300'>Sequence</th>
            <th className='border border-slate-300'>Game State</th>
            <th className='border border-slate-300'>Render Description</th>
            <th className='border border-slate-300'>Resolved Script</th>
          </tr>
        </thead>
        <tbody>
          {props.voiceOverAudits.map((audit) => {
            return (
              <tr key={audit.id}>
                <td className='border border-slate-300'>{audit.blockId}</td>
                <td className='border border-slate-300'>{audit.initiatedAt}</td>
                <td className='border border-slate-300'>{audit.sequence}</td>
                <td className='border border-slate-300 bg-secondary p-4'>
                  <pre className='text-left whitespace-pre-wrap text-2xs'>
                    {JSON.stringify(JSON.parse(audit.gameState), null, 2)}
                  </pre>
                </td>
                <td className='border border-slate-300 bg-secondary p-4'>
                  <pre className='text-left whitespace-pre-wrap text-2xs'>
                    {JSON.stringify(
                      JSON.parse(audit.renderDescription),
                      null,
                      2
                    )}
                  </pre>
                </td>
                <td className='border border-slate-300 text-left p-4'>
                  {audit.script}
                  <audio
                    className='pt-4 w-full'
                    controls
                    src={audit.media?.url}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </details>
  );
}

function GenerateGroupPhotoButton(props: { sessionId: string }): JSX.Element {
  const [isGenerating, setIsGenerating] = useState(false);
  const triggerConfirmationModal = useAwaitFullScreenConfirmCancelModal();

  const handleGenerateGroupPhoto = async () => {
    const { result } = await triggerConfirmationModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            This will delete this session’s group photo (if it exists), and a
            task to generate a new one will be enqueued. Check the memories page
            to see when it’s ready. For OnD sessions, the group photo email will
            not be sent to the organizer.
          </ConfirmCancelModalText>
        </div>
      ),
      cancelBtnLabel: 'Cancel',
      confirmBtnLabel: 'Confirm',
    });
    if (result !== 'confirmed') {
      return;
    }

    setIsGenerating(true);
    try {
      await apiService.session.generateSessionGroupPhoto(props.sessionId);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <button
      type='button'
      className='btn btn-secondary w-52 h-10 flex items-center justify-center'
      onClick={handleGenerateGroupPhoto}
      disabled={isGenerating}
    >
      {isGenerating ? (
        <Loading text='' imgClassName='w-5 h-5' />
      ) : (
        <>Generate Group Photo</>
      )}
    </button>
  );
}

const getTeamById = (snapshot: SnapshotData, teamId: TeamId): Team | null => {
  return snapshot.teams?.teams[teamId] || null;
};

const getParticipantByClientId = (
  snapshot: SnapshotData,
  clientId: string
): Participant | null => {
  return (snapshot.participants && snapshot.participants[clientId]) || null;
};

const getTeamMembersById = (
  snapshot: SnapshotData,
  teamId: TeamId
): Record<string, ParticipantEx[]> => {
  const members: Record<string, ParticipantEx[]> = {};
  const map = snapshot.teams && snapshot.teams['team-members'];
  if (!map) return members;
  const teamMemberMap = map[teamId];
  if (!teamMemberMap) return members;
  for (const member of Object.values(teamMemberMap)) {
    const p = getParticipantByClientId(snapshot, member.id);
    if (p) {
      const instances = members[p.id] || [];
      instances.push({ ...p, teamJoinedAt: member.joinedAt });
      members[p.id] = instances;
    } else {
      members[member.id] = [
        {
          clientId: member.id,
          username: 'Player not found',
          teamJoinedAt: member.joinedAt,
          joinedAt: 0,
          id: '',
          clientType: ClientType.Audience,
        },
      ];
    }
  }
  return members;
};

function DataViewer({
  venueId,
  session,
  data,
  voiceOverAudits,
  organizerLookup,
}: {
  venueId: string;
  session: SessionTrack;
  data: SnapshotData;
  voiceOverAudits: DtoSessionVoiceOverAudit[];
  organizerLookup: OrganizerLookup | null;
}): JSX.Element | null {
  const [copied, setCopied] = useState<Record<string, boolean>>({});
  const scoreboard = data.scoreboard
    ? 'teamScoreboard' in data.scoreboard
      ? data.scoreboard.teamScoreboard
      : data.scoreboard
    : [];
  const links = useMemo(() => {
    return [
      {
        name: 'Stage',
        link: linkForAgoraAnalytics(WebRTCUtils.ChannelFor('stage', venueId)),
      },
      {
        name: 'Ond',
        link: linkForAgoraAnalytics(WebRTCUtils.ChannelFor('ond', venueId)),
      },
      {
        name: 'Game',
        link: linkForAgoraAnalytics(WebRTCUtils.ChannelFor('game', venueId)),
      },
      {
        name: 'Music',
        link: linkForAgoraAnalytics(WebRTCUtils.ChannelFor('music', venueId)),
      },
      {
        name: 'Broadcast',
        link: linkForAgoraAnalytics(
          WebRTCUtils.ChannelFor('broadcast', venueId)
        ),
      },
    ];
  }, [venueId]);

  useEffect(() => {
    uncheckedIndexAccess_UNSAFE(window)['snapshot'] = data;
    return () => {
      delete uncheckedIndexAccess_UNSAFE(window)['snapshot'];
    };
  }, [data]);

  const handleCopy = (clientId: string): void => {
    const p = getParticipantByClientId(data, clientId);
    if (!p) return;
    copy(JSON.stringify(p));
    setCopied((prev) => {
      return { ...prev, [clientId]: true };
    });
    setTimeout(() => {
      setCopied((prev) => {
        delete prev[clientId];
        return { ...prev };
      });
    }, 3000);
  };

  const sessionId = session?.id ?? data.session?.id;

  const hosts = data.participants
    ? Object.values(data.participants).filter(
        (p) => p.clientType === ClientType.Host
      )
    : [];

  return (
    <div className='text-white w-full flex flex-col gap-4'>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center gap-4'>
          <Link to={`/sessions/${sessionId}/memories`} target='_blank'>
            <button type='button' className='btn-secondary w-48 h-10'>
              View Memories Page
            </button>
          </Link>

          <GenerateGroupPhotoButton sessionId={sessionId} />
        </div>

        <div className='text-sm my-1'>
          Agora Analytics:
          {links.map((l, i) => (
            <a
              key={`${i}-${l.link}`}
              href={l.link}
              target='_blank'
              rel='noreferrer'
              className='text-primary mx-1'
            >
              {l.name}
            </a>
          ))}
          <div className='text-sm'>
            You can inspect the snapshot object in console by `snapshot`.
          </div>
          <div className='text-sm flex gap-2'>
            Kibana Logs:
            <KibanaDiscoverLink
              className='text-primary'
              startedAt={data.session?.startedAt}
              endedAt={data.session?.endedAt}
              kql={`venueId : "${venueId}"`}
            >
              Venue Logs
            </KibanaDiscoverLink>
            <KibanaDiscoverLink
              className='text-primary'
              startedAt={data.session?.startedAt}
              endedAt={data.session?.endedAt}
              kql={`sessionId : "${sessionId}"`}
            >
              Session Logs
            </KibanaDiscoverLink>
            {hosts.map((p) => (
              <KibanaDiscoverLink
                className='text-primary'
                startedAt={data.session?.startedAt}
                endedAt={data.session?.endedAt}
                kql={`venueId : "${venueId}" and uid : "${p.id}"`}
              >
                Host / Cloud Controller Logs (
                {formatNameforParticipant(p, organizerLookup)} {p.id})
              </KibanaDiscoverLink>
            ))}
          </div>
          {voiceOverAudits.length > 0 && (
            <div className='text-sm'>
              <a href='#voice-over-audits' className='text-primary'>
                Jump to Voice Over Audits
              </a>
            </div>
          )}
        </div>
      </div>
      <table className='table-auto border-collapse border border-slate-400 w-full text-center'>
        <thead>
          <tr>
            <th className='border border-slate-300'>Rank</th>
            <th className='border border-slate-300'>Team</th>
            <th className='border border-slate-300'>Score</th>
            <th className='border border-slate-300'>Members</th>
          </tr>
        </thead>
        <tbody>
          {scoreboard.map((e) => {
            // Join the userIds into KQL like `uid: XXX or uid: XXX`
            const teamUidKql = Object.keys(getTeamMembersById(data, e.teamId))
              .map((id) => `uid: "${id}"`)
              .join(' or ');

            return (
              <tr key={e.teamId}>
                <td className='border border-slate-300'>{e.rank}</td>
                <td className='border border-slate-300'>
                  <p>{getTeamById(data, e.teamId)?.name || e.teamName}</p>

                  <>
                    <p className='text-3xs'>
                      {WebRTCUtils.ChannelFor('team', venueId, e.teamId)}
                    </p>
                    <ul className='text-3xs'>
                      <li>
                        <KibanaDiscoverLink
                          className='text-3xs text-primary'
                          startedAt={data.session?.startedAt}
                          endedAt={data.session?.endedAt}
                          kql={`venueId : "${venueId}" and ( ${teamUidKql} )`}
                        >
                          Team Logs (via UserIds)
                        </KibanaDiscoverLink>
                      </li>
                      <li>
                        <KibanaDiscoverLink
                          className='text-3xs text-primary'
                          startedAt={data.session?.startedAt}
                          endedAt={data.session?.endedAt}
                          kql={`venueId : "${venueId}" and scope : "experience-score" and ( ${teamUidKql} )`}
                        >
                          Team Experience Scores (via UserIds)
                        </KibanaDiscoverLink>
                      </li>
                    </ul>
                  </>
                </td>
                <td className='border border-slate-300'>{e.score}</td>
                <td className='border border-slate-300 text-sm'>
                  {Object.entries(getTeamMembersById(data, e.teamId)).map(
                    ([id, instances]) => {
                      return (
                        <div
                          key={id}
                          className='flex flex-col items-center justify-center'
                        >
                          {instances.map((p) => {
                            const name = formatNameforParticipant(
                              p,
                              organizerLookup
                            );

                            return (
                              <div
                                key={p.clientId}
                                className='w-full flex flex-col border-b border-secondary px-4 py-2 items-start'
                              >
                                <p>
                                  <span className='text-secondary mr-1'>
                                    Username:
                                  </span>
                                  {name}
                                </p>
                                <p>
                                  <span className='text-secondary mr-1'>
                                    Game Joined At:
                                  </span>
                                  {formatter.format(p.joinedAt)}
                                </p>
                                <p>
                                  <span className='text-secondary mr-1'>
                                    Team Joined At:
                                  </span>
                                  {p.teamJoinedAt === 0
                                    ? 'Unknown'
                                    : formatter.format(p.teamJoinedAt)}
                                </p>
                                <p>
                                  <span className='text-secondary mr-1'>
                                    Client ID:
                                  </span>
                                  {p.clientId}
                                </p>
                                <p>
                                  <span className='text-secondary mr-1'>
                                    User ID:
                                  </span>
                                  {p.id || 'Unknown'}
                                </p>
                                <button
                                  type='button'
                                  className='btn-primary w-20 h-6 text-xs'
                                  disabled={!p.id || copied[p.clientId]}
                                  onClick={() => handleCopy(p.clientId)}
                                >
                                  {copied[p.clientId] ? 'Copied' : 'Copy Raw'}
                                </button>
                                <a
                                  className='text-xs text-primary'
                                  href={linkForAgoraAnalytics(
                                    WebRTCUtils.ChannelFor(
                                      'team',
                                      venueId,
                                      e.teamId
                                    )
                                  )}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  Agora Team Channel
                                </a>
                                <KibanaDiscoverLink
                                  className='text-xs text-primary'
                                  startedAt={data.session?.startedAt}
                                  endedAt={data.session?.endedAt}
                                  kql={`venueId : "${venueId}" and uid : "${p.id}"`}
                                >
                                  User Logs
                                </KibanaDiscoverLink>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className='my-4'>
        <header>Raw Data</header>
        <details>
          <summary className='my-1 text-sm'>participants.json</summary>
          <pre className='bg-secondary px-2 text-2xs'>
            {JSON.stringify(data.participants, null, 2)}
          </pre>
        </details>
        <details>
          <summary className='my-1 text-sm'>teams.json</summary>
          <pre className='bg-secondary px-2 text-2xs'>
            {JSON.stringify(data.teams, null, 2)}
          </pre>
        </details>
        <details>
          <summary className='my-1 text-sm'>scoreboard.json</summary>
          <pre className='bg-secondary px-2 text-2xs'>
            {JSON.stringify(data.scoreboard, null, 2)}
          </pre>
        </details>
        <details>
          <summary className='my-1 text-sm'>session.json</summary>
          <pre className='bg-secondary px-2 text-2xs'>
            {JSON.stringify(data.session, null, 2)}
          </pre>
        </details>
        <details>
          <summary className='my-1 text-sm'>playback-info.json</summary>
          <pre className='bg-secondary px-2 text-2xs'>
            {JSON.stringify(data['playback-info'], null, 2)}
          </pre>
        </details>
        <details>
          <summary className='my-1 text-sm'>game-log.json</summary>
          <pre className='bg-secondary px-2 text-2xs'>
            {JSON.stringify(data['game-log'], null, 2)}
          </pre>
        </details>
      </div>
      <VoiceOverAuditsTable voiceOverAudits={voiceOverAudits} />
      <GameLogTable
        organizerLookup={organizerLookup}
        snapshot={data}
        sessionTrack={session}
      />
    </div>
  );
}

function GameHealthSummary(props: {
  sessionId: string | null;
  maxPlayers: number;
}) {
  const swr = useSWR(
    ['sentry-stats', props.sessionId],
    async ([, sessionId]) => {
      const url = new APIServiceURL(`/sessions/${sessionId}/sentry-stats`);
      const res = await (sessionId
        ? publicFetchAPIService<DtoGetSessionSentryStatsResponse>(url, {
            token: getToken(),
          })
        : null);
      if (!res) return null;
      return res.json;
    }
  );

  const sentryLinks: [URL, string][] = [];

  const urlFromDSN = () => {
    const url = new URL(sentry(getEnv()).dsn);
    url.username = '';
    url.password = '';
    url.pathname = `organizations/${
      sentry(getEnv()).organization
    }/discover/results/`;

    // These seem to be mandatory :(
    url.searchParams.append('field', 'title');
    url.searchParams.append('field', 'event');
    url.searchParams.append('field', 'project');
    url.searchParams.append('field', 'user.display');
    url.searchParams.append('field', 'timestamp');
    url.searchParams.append('name', 'All Events');

    return url;
  };

  try {
    const url = urlFromDSN();
    url.searchParams.set('query', `sessionId:${props.sessionId}`);
    url.searchParams.set('statsPeriod', '30d');
    sentryLinks.push([url, 'All Session Errors']);
  } catch (err) {}

  try {
    const url = urlFromDSN();
    url.searchParams.set(
      'query',
      `sessionId:${props.sessionId} error.unhandled:true`
    );
    url.searchParams.set('statsPeriod', '30d');
    sentryLinks.push([url, 'Unhandled Session Errors']);
  } catch (err) {}

  const unhandledErrorsPerPlayer =
    swr.data?.totalErrorsUnhandledCounts && props.maxPlayers
      ? swr.data.totalErrorsUnhandledCounts / props.maxPlayers
      : 0;
  const handledErrorsPerPlayer =
    swr.data?.totalErrorsHandledCount && props.maxPlayers
      ? swr.data.totalErrorsHandledCount / props.maxPlayers
      : 0;

  const stats = [
    {
      label: 'Unhandled',
      value: swr.data?.totalErrorsUnhandledCounts ?? 0,
      text: swr.data?.totalErrorsUnhandledCounts ?? '-',
      warn: 'bg-error bg-opacity-75',
    },
    {
      label: 'Unhandled (Host / Cloud Controller)',
      value: swr.data?.controllerErrorsUnhandledCounts ?? 0,
      text: swr.data?.controllerErrorsUnhandledCounts ?? '-',
      warn: 'bg-error bg-opacity-75',
    },
    {
      label: 'Unhandled Per Player',
      value: unhandledErrorsPerPlayer,
      text: unhandledErrorsPerPlayer.toFixed(2),
      warn: 'bg-warning bg-opacity-50',
    },
    {
      label: 'Handled',
      value: swr.data?.totalErrorsHandledCount ?? 0,
      text: swr.data?.totalErrorsHandledCount ?? '-',
    },
    {
      label: 'Handled (Host / Cloud Controller)',
      value: swr.data?.controllerErrorsHandledCount ?? 0,
      text: swr.data?.controllerErrorsHandledCount ?? '-',
    },

    {
      label: 'Handled Per Player',
      value: handledErrorsPerPlayer,
      text: handledErrorsPerPlayer.toFixed(2),
    },
  ];

  const elements = [];
  for (const stat of stats) {
    const warnClassName = stat.value > 0 && stat.warn ? 'bg-error' : '';
    elements.push(
      <tr key={stat.label}>
        <th className='border border-gray-700 font-normal'>{stat.label}</th>
        <td className={`border border-gray-700 ${warnClassName}`}>
          {stat.text}
        </td>
      </tr>
    );
  }

  return (
    <div className='text-white flex flex-col gap-4'>
      <details className='flex flex-col gap-2'>
        <summary>Session Health</summary>
        <div className='relative flex flex-col gap-2'>
          {swr.isLoading ? (
            <div
              className='
                absolute top-0 right-0 bottom-0 left-0 bg-modal opacity-75
                flex justify-center items-center
                z-3
              '
            >
              <span className='italic'>(Loading...)</span>
            </div>
          ) : null}

          <ul className='flex gap-2'>
            {sentryLinks.map(([url, label]) => (
              <li>
                <a href={url.toString()} className='text-primary flex gap-0'>
                  {label}
                </a>
              </li>
            ))}
          </ul>

          <table className='table-auto border-collapse border w-full text-center text-sm'>
            <thead>
              <tr>
                <th className='border border-slate-300'>Error Type</th>
                <th className='border border-slate-300'>Error Count</th>
              </tr>
            </thead>
            <tbody>{elements}</tbody>
          </table>
        </div>
      </details>
    </div>
  );
}

function SessionTrackSummary(props: { session: SessionTrack | null }) {
  return !props.session ? null : (
    <div className='text-white flex flex-col gap-4'>
      {/* This is really useful info, so just repeat it within the detail view! */}
      <SessionTrackTable
        items={[props.session]}
        config={{
          organizer: true,
          mode: true,
          org: true,
          actionSheet: {
            showEdit: false,
            showCopyStreamId: true,
            showInspect: false,
          },
          controller: true,
          adminLinks: true,
          localTime: true,
        }}
      />
      <details>
        <summary>Tracked Session Data</summary>
        <pre className='bg-secondary p-2 text-2xs'>
          {JSON.stringify(props.session, null, 2)}
        </pre>
      </details>
    </div>
  );
}

function TeamScoreSummary(props: {
  data: SnapshotData;
  organizerLookup: OrganizerLookup | null;
}) {
  const { data, organizerLookup } = props;
  const scoreboard = data.scoreboard
    ? 'teamScoreboard' in data.scoreboard
      ? data.scoreboard.teamScoreboard
      : data.scoreboard
    : [];

  const lines: string[] = [];

  for (const scoreEntry of scoreboard) {
    const teamId = scoreEntry.teamId;
    const team = getTeamById(data, teamId)?.name || scoreEntry.teamName;

    // Each team gets its own line
    lines.push(`${scoreEntry.rank}. ${team} (Score: ${scoreEntry.score})`);
    lines.push(''); // blank line after each team name

    for (const [, instances] of Object.entries(
      getTeamMembersById(data, teamId)
    )) {
      for (const p of instances) {
        const organizer = organizerLookup?.get(p.id);
        const fullName = `${organizer?.firstName} ${organizer?.lastName}`;
        const name = `${p.username}${organizer ? ` (${fullName})` : ''}`;
        // Each team member is a list item
        lines.push(`- ${name}`);
      }
    }

    lines.push(''); // blank line after all team members
  }

  const summary = lines.join('\n');

  const [onExecuteCopy, label] = useCopyTextLink({
    label: <>Copy</>,
    getter: () => summary,
  });

  return (
    <div className='text-white flex flex-col gap-4'>
      <details>
        <summary>
          <span className='relative'>
            Team Score Summary{' '}
            <button
              type='button'
              className='
                absolute
                left-[calc(100%+1rem)] top-1/2 transform -translate-y-1/2
                btn btn-secondary text-xs
                px-4 py-2
                flex items-center gap-1
              '
              onClick={() => onExecuteCopy()}
            >
              <CopyIcon className='w-3 h-3 fill-current' />
              <span className='relative min-w-10'>{label}</span>
            </button>
          </span>
        </summary>
        <pre className='bg-secondary p-2 text-2xs'>{summary}</pre>
      </details>
    </div>
  );
}

type OrganizerLookup = Map<Organizer['uid'], Organizer>;

async function loadOrganizerLookup(
  orgId: Organization['id'] | null,
  userIds: Set<User['id']> | null
): Promise<OrganizerLookup | null> {
  if (!orgId || !userIds?.size) return null;
  const reqs = [];
  for (const uid of userIds) {
    // .catch() since this is best-effort and might 404
    reqs.push(apiService.organization.getOrganizer(orgId, uid).catch());
  }

  const res = await Promise.allSettled(reqs);

  const organizers = res
    .map((res) =>
      res.status === 'fulfilled' ? res.value.data.organizer : null
    )
    .filter((o) => Boolean(o)) as Organizer[];

  const lookup = new Map(organizers.map((o) => [o.uid, o]));
  return lookup;
}

function formatNameforParticipant(
  p: Participant | null,
  organizerLookup: OrganizerLookup | null
) {
  if (!p) return 'Unknown';
  const organizer = organizerLookup?.get(p.id);
  const fullName = `${organizer?.firstName} ${organizer?.lastName}`;
  const name = `${p.username}${organizer ? ` (${fullName})` : ''}`;
  return name;
}

async function loadStreamInspectorData(parsed: ParsedStreamId) {
  const sessionResp = await apiService.session.getSession(parsed.sessionId);
  const session = sessionResp.data.session;
  const [venueResp, voiceOverAudits] = await Promise.all([
    apiService.venue.getVenueSessionSnapshot(session.venueId, parsed.sessionId),
    apiService.session.getSessionVoiceOverAudits(parsed.sessionId),
  ]);

  const participants = venueResp.data.data.participants ?? {};
  const uniqueUserIds = new Set<User['id']>(
    Object.values(participants)
      .map((p) => p.id)
      .filter((id) => Boolean(id))
  );

  const organizerLookup = await loadOrganizerLookup(
    sessionResp.data.session.orgId,
    uniqueUserIds
  );

  return {
    venueId: session.venueId,
    sessionId: parsed.sessionId,
    session: sessionResp.data.session,
    venue: venueResp.data.data,
    voiceOverAudits: voiceOverAudits.data.voiceOverAudits ?? [],
    organizerLookup,
  };
}

function SessionInspector(): JSX.Element | null {
  const [searchParams, setSearchParams] = useSearchParams();

  const { register, handleSubmit, formState, setError, getValues, trigger } =
    useForm<FormData>({
      defaultValues: {
        streamId: searchParams.get('streamId') || '',
      },
    });

  useEffect(() => {
    // validate on page load since this page is a form and allows populating through the URL
    trigger('streamId');
  }, [trigger]);

  const { data, error, isValidating } = useSWRImmutable(
    // if this doesn't parse, it will throw and prevent loading
    () => parseStreamId(getValues('streamId')),
    loadStreamInspectorData
  );

  if (error && !formState.errors.streamId) {
    // Pass errors from the request into the form state. An alternative is to
    // have a separate error area for request-specific errors
    setError('streamId', {
      type: error.name,
      message: error.message,
    });
  }

  return (
    <div className='px-10 pb-10 flex flex-col gap-8'>
      <form
        onSubmit={handleSubmit((data) => setSearchParams(data))}
        className=''
      >
        <div className='flex flex-row items-center'>
          <input
            className={`${
              formState.errors.streamId ? 'field-error' : 'field'
            } w-100 h-8 p-2 m-0`}
            {...register('streamId', {
              required: true,
              validate: (value) => {
                try {
                  parseStreamId(value);
                  return true;
                } catch (err) {
                  return err2s(err) ?? '';
                }
              },
            })}
            placeholder='StreamID/SessionID'
          />
          <button
            type='submit'
            className='btn-primary w-30 h-8 ml-4 text-sm flex items-center justify-center'
            disabled={isValidating}
          >
            {isValidating && (
              <Loading
                text=''
                imgClassName='w-5 h-5'
                containerClassName='mr-2'
              />
            )}
            Inspect
          </button>
        </div>
        <ErrorMessage
          errors={formState.errors}
          name='streamId'
          render={({ message }) => (
            <p className='text-red-002 text-sms my-1'>{message}</p>
          )}
        />
      </form>
      {data && (
        <div className='flex flex-col gap-8'>
          <SessionTrackSummary session={data.session} />
          <GameHealthSummary
            sessionId={data.sessionId}
            maxPlayers={data.session.maxPlayers}
          />
          <TeamScoreSummary
            data={data.venue}
            organizerLookup={data.organizerLookup}
          />
          <DataViewer
            venueId={data.venueId}
            session={data.session}
            data={data.venue}
            voiceOverAudits={data.voiceOverAudits}
            organizerLookup={data.organizerLookup}
          />
        </div>
      )}
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default SessionInspector;
