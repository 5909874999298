import pluralize from 'pluralize';
import { useController, useFormContext } from 'react-hook-form';

import {
  getFeatureQueryParamNumber,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { type PlayerRange } from '../../types/game';
import { PlayerRangeUtils } from '../Game/PlayerRangeUtils';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { TeamIcon } from '../icons/TeamIcon';
import { useMyOrgConnection, useMyOrgId } from '../Organization';
import { OrganizerMultiSelect } from '../Organization/OrganizerSelect';
import {
  OrganizerGroupSelect,
  type OrganizerSelectOption,
} from '../Organization/OrganizerSelectGroup';
import { useTriggerBookNow } from '../Product/BookNow';
import { useUser } from '../UserContext';
import { type EventEditorData } from './Editor';

const ATTENDEE_HARD_LIMIT = getFeatureQueryParamNumber(
  'event-attendees-max-count'
);

function Warning(props: {
  seatCapLimit?: number;
  attendees: OrganizerSelectOption[];
  gamePackPlayerRange?: PlayerRange;
}) {
  const { getValues } = useFormContext<EventEditorData>();
  const triggerBookNow = useTriggerBookNow();

  const handleUpgradeClick = useLiveCallback(async () => {
    const gamePackId = getValues('gamePackId');
    if (gamePackId) {
      await triggerBookNow(gamePackId, 'event-scheduler-upgrade');
    }
  });

  if (
    props.seatCapLimit !== undefined &&
    props.attendees.length + 1 > props.seatCapLimit
  ) {
    return (
      <div className='text-tertiary text-xs'>
        Your plan limits the number of players in your venue to{' '}
        {props.seatCapLimit}.{' '}
        <button
          type='button'
          onClick={handleUpgradeClick}
          className='btn underline'
        >
          Upgrade
        </button>
      </div>
    );
  }

  if (props.attendees.length + 1 >= ATTENDEE_HARD_LIMIT) {
    return (
      <div className='text-tertiary text-xs'>
        You’ve reached the maximum number of attendees that you can invite
        through this form.
      </div>
    );
  }

  if (props.gamePackPlayerRange === undefined) return null;

  const inRange = PlayerRangeUtils.InRange(
    props.gamePackPlayerRange,
    props.attendees.length + 1
  );

  return (
    <div
      className={`${
        inRange === 0 ? 'text-secondary' : 'text-tertiary'
      } text-xs flex items-center gap-1`}
    >
      <div>
        {PlayerRangeUtils.Format(
          props.gamePackPlayerRange,
          'participant',
          null
        )}{' '}
        recommended
      </div>
      {inRange === 0 ? (
        <FilledCheckIcon className='w-3 h-3 fill-current' />
      ) : (
        <></>
      )}
    </div>
  );
}

function InviteNotice(props: { attendees: OrganizerSelectOption[] }) {
  const { attendees } = props;
  const newInvitees = attendees.filter(
    (a) => a.kind === 'slack-user' || a.kind === undefined
  );
  if (newInvitees.length === 0) return null;
  return (
    <div className='text-xs'>
      ✉️ {newInvitees.length} {pluralize('invitee', newInvitees.length)}{' '}
      {newInvitees.length === 1 ? 'is' : 'are'} not on Luna Park yet. We’ll
      automatically create accounts for them!
    </div>
  );
}

export function EventEditorAttendeesField(props: {
  gamePackPlayerRange?: PlayerRange;
  seatCapLimit?: number;
}): JSX.Element | null {
  const slackSelectEnabled = useFeatureQueryParam(
    'event-attendees-select-slack'
  );
  const orgId = useMyOrgId();
  const orgConnection = useMyOrgConnection();
  const user = useUser();
  const {
    field: { value: attendees, onChange },
  } = useController<EventEditorData, 'attendees'>({
    name: 'attendees',
    rules: {
      validate: (attendees: OrganizerSelectOption[]) => {
        return attendees.every((attendee) => attendee.kind !== 'slack-channel');
      },
    },
  });

  if (!orgId) return null;

  return (
    <div className='flex items-start gap-3 text-white'>
      <div className='h-10 flex items-center justify-center'>
        <TeamIcon className='fill-current w-5 h-5' />
      </div>
      <div className='flex-1 flex flex-col gap-1'>
        <div className='text-sms font-bold'>
          Enter the name or email. Separate emails by comma, space or
          Enter/Return.
        </div>
        {orgConnection &&
        orgConnection.status === 'active' &&
        slackSelectEnabled ? (
          <OrganizerGroupSelect
            orgId={orgId}
            options={attendees}
            onChange={(value) =>
              onChange(value.slice(0, ATTENDEE_HARD_LIMIT - 1))
            }
            placeholder='Start typing to search for a slack channel, or add attendees one at a time by name.'
            isOptionDisabled={() => attendees.length >= ATTENDEE_HARD_LIMIT - 1}
            filterOrganizer={(organizer) => organizer.uid !== user.id}
            creatable
          />
        ) : (
          <OrganizerMultiSelect
            orgId={orgId}
            options={
              attendees.filter(
                (v) => v.kind === 'organizer' || v.kind === undefined
              ) as never
            }
            onChange={(value) =>
              onChange(value.slice(0, ATTENDEE_HARD_LIMIT - 1))
            }
            placeholder='Who do you want to play with?'
            isOptionDisabled={() => attendees.length >= ATTENDEE_HARD_LIMIT - 1}
            filterOrganizer={(organizer) => organizer.uid !== user.id}
            creatable
            maxAttendees={
              props.seatCapLimit !== undefined
                ? // -1 to account for the organizer.
                  props.seatCapLimit - 1
                : undefined
            }
          />
        )}

        <div className='w-full flex flex-col gap-1 mt-1'>
          <InviteNotice attendees={attendees} />
          <Warning
            seatCapLimit={props.seatCapLimit}
            attendees={attendees}
            gamePackPlayerRange={props.gamePackPlayerRange}
          />
        </div>
      </div>
    </div>
  );
}
