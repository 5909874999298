import { useNavigate } from '@remix-run/react';
import { useMemo } from 'react';
import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import {
  type MessageCampaign,
  type MessageCampaignsResponse,
  MessageCampaignType,
} from '../../services/api-service/messageCampaign.api';
import { type Action, ActionSheet } from '../ActionSheet';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ChatSendIcon } from '../icons/Chat/ChatSendIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { DuplicateIcon } from '../icons/DuplicateIcon';

async function getMessageCampaigns(): Promise<MessageCampaignsResponse> {
  const resp = await apiService.messageCampaign.query();
  return resp.data;
}

export const MessageCampaignList = (): JSX.Element => {
  const { data, mutate } = useSWR('/message-campaigns', getMessageCampaigns, {
    shouldRetryOnError: false,
  });

  const sorted = data?.campaigns?.sort((a, b) => b.name.localeCompare(a.name));

  return (
    <div className='px-10 pb-10 text-white'>
      <header className='flex justify-between items-center'>
        <div className='text-2xl font-medium'>Message Campaigns</div>
      </header>
      <table className='w-full mt-10'>
        <thead>
          <tr className='text-left h-14 '>
            <th className='pl-2'>Name</th>
            <th>Trigger (T0)</th>
            <th>Integrations</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sorted?.map((campaign) => (
            <MessageCampaignRow
              key={campaign.id}
              campaign={campaign}
              handleRefresh={mutate}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const MessageCampaignRow = (props: {
  campaign: MessageCampaign;
  handleRefresh: () => void;
}): JSX.Element => {
  const { campaign, handleRefresh } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/admin/toolkit/message-campaigns/${campaign.id}`);
  };

  const onDuplicate = async () => {
    await apiService.messageCampaign.duplicateCampaign(campaign.id);
    handleRefresh();
  };

  const triggerWaitConfirmModal = useAwaitFullScreenConfirmCancelModal();

  const onDelete = async () => {
    const decision = await triggerWaitConfirmModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-white text-center'>
          <h2 className=' text-2xl font-medium'>
            Are you sure you want to delete this campaign?
          </h2>
          <p className='text-sms mt-4'>This action can't be undone.</p>
        </div>
      ),
      confirmBtnLabel: 'Confirm',
      cancelBtnLabel: 'Cancel',
    });
    if (decision.result === 'canceled') return;
    await apiService.messageCampaign.delete(campaign.id);
    handleRefresh();
  };

  return (
    <tr
      className='h-10 text-sms cursor-pointer hover:bg-lp-gray-002'
      onClick={handleClick}
    >
      <td className='uppercase pl-2'>{campaign.name}</td>
      <td>{campaign.triggerDescription}</td>
      <td>
        {campaign.messageCampaignType === MessageCampaignType.GlobalRound
          ? campaign.integrationCount
          : 'N/A'}
      </td>
      <td>
        <div onClick={(e) => e.stopPropagation()}>
          <CampaignActionSheet
            onDuplicate={
              campaign.messageCampaignType === MessageCampaignType.GlobalRound
                ? onDuplicate
                : null
            }
            onDelete={
              campaign.integrationCount === 0 &&
              campaign.messageCampaignType === MessageCampaignType.GlobalRound
                ? onDelete
                : null
            }
            onMessageSent={() =>
              navigate(
                `/admin/toolkit/message-campaigns/${campaign.id}/messages`
              )
            }
          />
        </div>
      </td>
    </tr>
  );
};

export function CampaignActionSheet(props: {
  onDuplicate: (() => void) | null;
  onDelete: (() => void) | null;
  onMessageSent: () => void;
}): JSX.Element {
  const { onDuplicate, onDelete, onMessageSent } = props;

  const actions: Action<string>[] = useMemo(() => {
    const campaignActions: Action<string>[] = [
      {
        kind: 'button',
        key: 'Message',
        icon: <ChatSendIcon />,
        text: 'Message Sent',
        onClick: onMessageSent,
        className: 'disabled',
      },
    ];

    if (onDuplicate) {
      campaignActions.push({
        kind: 'button',
        key: 'Duplicate',
        icon: <DuplicateIcon />,
        text: 'Duplicate',
        onClick: onDuplicate,
        className: 'disabled',
      });
    }
    if (onDelete) {
      campaignActions.push({
        kind: 'button',
        key: 'Delete',
        icon: <DeleteIcon />,
        text: 'Delete',
        onClick: onDelete,
        className: 'disabled text-red-005',
      });
    }
    return campaignActions;
  }, [onDelete, onDuplicate, onMessageSent]);
  return <ActionSheet<string> actions={actions} />;
}
