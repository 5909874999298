import { apiService } from '../../../services/api-service';
import { type MessageTemplate } from '../../../types/messageTemplate';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';

export function useTriggerDeleteMessageTemplateModal(): (
  template: MessageTemplate,
  onDeleted?: () => void
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return async (template: MessageTemplate, onDeleted?: () => void) => {
    const response = await triggerFullScreenModal({
      kind: 'confirm-cancel',
      prompt: (
        <ConfirmCancelModalText className=' text-2xl font-medium'>
          Are you sure you want to delete this template?
        </ConfirmCancelModalText>
      ),
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
    });

    if (response.result === 'canceled') return;

    await apiService.messageTemplate.delete(template.id);
    onDeleted && onDeleted();
  };
}
