import { useNavigate } from '@remix-run/react';
import { addMinutes, format } from 'date-fns';
import useSWRImmutable from 'swr/immutable';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { getQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { type CreateEventRequest } from '../../services/api-service/event.api';
import { fromDTOGamePack, fromDTOOrganizer } from '../../utils/api-dto';
import {
  ConfirmCancelModalHeading,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { parseUserIdsFromOptions } from '../Organization/OrganizerSelectGroup';
import {
  createNewVenueOption,
  emptyProducer,
  EventEditor,
  type EventEditorData,
} from './Editor';

function toCreateEventRequest(
  data: EventEditorData
): CreateEventRequest | null {
  if (!data.gamePackId) return null;

  const { startDate, timezone } = data.eventTime;
  const endAt = addMinutes(startDate, data.durationMin).toISOString();

  return {
    gamePackId: data.gamePackId,
    message: data.message,
    startAt: startDate.toISOString(),
    endAt: endAt,
    timezone,
    organizerUid: data.organizerUid ?? null,
    attendees: parseUserIdsFromOptions(data.attendees),
    orgId: data.orgId ?? undefined,
    orgName: data.orgName,
    organizerName: data.organizerName,
    organizerEmail: data.organizerEmail,
    attendeeEmails: data.attendeeEmails,
    venueId: data.venueOption?.value,
    hostUid: data.hostUid,
    studioId: data.studioOption?.value,
    type: 'live',
    prepMin: data.prepMin,
    producerUid: data.producerUid,
    eventTitle: data.eventTitle,
    eventFirstParagraph: data.eventFirstParagraph,
    hostShoutOut: data.hostShoutOut,
    vipOnStage: data.vipOnStage,
  };
}

async function makeInitialData(liveEventRequestId: string) {
  const request = (
    await apiService.event.getLiveEventRequest(liveEventRequestId)
  ).data.request;
  const studios = (await apiService.event.liveStudio()).data.studios;

  const result: Partial<EventEditorData> = {};
  result.gamePackId = request.gamePackId;
  result.organizerUid = request.requesterUid;
  result.attendees = request.attendees?.map((organizer) => ({
    kind: 'organizer',
    organizer: fromDTOOrganizer(organizer),
  }));
  result.type = 'live';
  result.durationMin = request.gamePack
    ? Math.ceil(request.gamePack.approximateDurationSeconds / 60)
    : undefined;
  result.gamePack = fromDTOGamePack(request.gamePack) || undefined;
  result.eventTime = {
    startDate: new Date(request.startAt),
    timezone: request.timezone,
  };
  result.organizer = fromDTOOrganizer(request.requester) || undefined;
  result.orgId = request.orgId;
  result.orgName = request.requester?.organization?.name;
  result.venueOption = createNewVenueOption;
  result.hostShoutOut = request.hostShoutOut;
  const studio = studios.find((studio) =>
    studio.name.toLowerCase().includes('live 2.0')
  );
  result.studioOption = studio
    ? {
        label: studio.name,
        value: studio.id,
      }
    : undefined;
  result.producerUid = emptyProducer.uid;
  return result;
}

export function LiveEventCreate(): JSX.Element | null {
  const liveEventRequestId = getQueryParam('live-event-request-id');
  const { data: initialData, isLoading } = useSWRImmutable(
    liveEventRequestId
      ? [liveEventRequestId, '/events/live-event-request']
      : null,
    async ([id]) => makeInitialData(id)
  );

  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const navigate = useNavigate();

  const onBeforeSubmit = useLiveCallback(async (data: EventEditorData) => {
    const req = toCreateEventRequest(data);
    if (!req) return false;

    const res = await triggerFullScreenModal({
      boxDimensionsClassName: ' w-170 h-120',
      cancelBtnClassName: 'w-40 h-10',
      confirmBtnClassName: 'w-40 h-10',
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>
            Verify the Event’s Details
          </ConfirmCancelModalHeading>
          <div className='mt-4 text-sms font-normal text-white'>
            <div className='flex flex-col items-start w-120 mt-10'>
              <div>This event is scheduled for</div>
              <div className='text-yellow-400'>
                {format(new Date(req.startAt), 'EEEE, MMMM do @ h:mm aa')}
              </div>
              <br />
              <div>
                Organization:{' '}
                {data.organizer?.organization?.name ?? req.orgName}
              </div>
              <div>Host: {data.host?.name}</div>
              <div>Studio: {data.studioOption?.label}</div>
              <div>Venue: {data.venueOption?.label}</div>
              <div>Prep Time: {data.prepMin ?? 0} minutes</div>
              <div>Duration: {data.durationMin ?? 0} minutes</div>
              <br />
              <div>The invite will be sent to:</div>
              <div>
                {data.organizer
                  ? `${data.organizer.firstName} ${data.organizer.lastName}`
                  : data.organizerName}{' '}
                - {data.organizer?.email ?? data.organizerEmail}
              </div>
            </div>
          </div>
        </div>
      ),
      confirmBtnLabel: 'Schedule',
      confirmBtnVariant: 'primary',
      cancelBtnLabel: 'Back',
    });
    return res.result !== 'canceled';
  });

  const onSubmit = useLiveCallback(async (data: EventEditorData) => {
    const req = toCreateEventRequest(data);
    if (!req) return;
    await apiService.event.create(req);
    navigate('../list');
  });

  if (isLoading) return null;
  return (
    <div className='flex justify-center'>
      <div className='w-full h-full'>
        <EventEditor
          title={
            <div className='flex items-center gap-2'>
              <span>Schedule a Live Game</span>
            </div>
          }
          initialData={initialData}
          subtitle='Scheduling a live game for an organization will send the org invite and create the internal calendar event.'
          onBeforeSubmit={onBeforeSubmit}
          onSubmit={onSubmit}
          onCancel={() => navigate(-1)}
          eventType='live'
          context='create'
        />
      </div>
    </div>
  );
}
