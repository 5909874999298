import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import pluralize from 'pluralize';
import {
  type ActionMeta,
  components,
  type GroupBase,
  type MultiValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { match } from 'ts-pattern';

import { type DtoSlackUser } from '@lp-lib/api-service-client/public';

import { useSlackAnalytics } from '../../analytics/slack';
import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { type Organizer, OrganizerUtils } from '../../types';
import { type SlackChannel } from '../../types/slack';
import { fromDTOOrganizer } from '../../utils/api-dto';
import { assertExhaustive, isValidEmail } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { Tooltip } from '../common/Tooltip';

type OptionOrganizer = {
  kind: 'organizer';
  organizer: Organizer;
  source?: 'organizer' | 'slack-user' | 'slack-channel';
};

type OptionSlackUser = {
  kind: 'slack-user';
  user: DtoSlackUser;
};

type OptionSlackChannel = {
  kind: 'slack-channel';
  channel: SlackChannel;
  users: DtoSlackUser[];
};

type OptionNew = {
  kind: undefined;
  __isNew__: true;
  value: string;
};

export type OrganizerSelectOption =
  | OptionOrganizer
  | OptionSlackUser
  | OptionSlackChannel
  | OptionNew;

export function parseOrganizersFromOptions(
  options: OrganizerSelectOption[] | null | undefined
): Organizer[] {
  if (!options) return [];

  const organizers: Organizer[] = [];
  for (const option of options) {
    const kind = option.kind;
    switch (kind) {
      case 'organizer':
        organizers.push(option.organizer);
        break;
      case 'slack-user':
        if (!!option.user.organizer) {
          organizers.push(fromDTOOrganizer(option.user.organizer));
        }
        break;
      case 'slack-channel':
      case undefined:
        break;
      default:
        assertExhaustive(kind);
        break;
    }
  }

  return organizers;
}

export function parseUserIdsFromOptions(
  options: OrganizerSelectOption[] | null | undefined
): string[] {
  if (!options) return [];
  return parseOrganizersFromOptions(options).map((o) => o.uid);
}

export function parseAnalyticsPropertiesFromOptions(
  options: OrganizerSelectOption[] | null | undefined
) {
  if (!options) return {};
  const organizerOptions = options.filter(
    (o) => o.kind === 'organizer'
  ) as OptionOrganizer[];
  return {
    slackChannelInvitedAttendees: organizerOptions.filter(
      (o) => o.source === 'slack-channel'
    ).length,
    slackUserInvitedAttendees: organizerOptions.filter(
      (o) => o.source === 'slack-user'
    ).length,
  };
}

export function organizersToSelectOptions(
  organizers: Organizer[] | null | undefined
): OrganizerSelectOption[] {
  if (!organizers) return [];
  return organizers.map((o) => ({ kind: 'organizer', organizer: o }));
}

type GroupedOption =
  | {
      kind: 'slack-users';
      label: string;
      options: OptionSlackUser[];
    }
  | {
      kind: 'channels';
      label: string;
      options: OptionSlackChannel[];
    };

function formatGroupLabel(group: GroupBase<OrganizerSelectOption>) {
  return (
    <div className='text-white text-sms font-bold transform-none'>
      {group.label}
    </div>
  );
}

type OptionPrefixFormatter = (option: OrganizerSelectOption) => string;

function formatMenu(
  option: OrganizerSelectOption,
  prefixFormatter?: OptionPrefixFormatter
) {
  const prefix = prefixFormatter?.(option);
  return match(option)
    .with({ kind: 'organizer' }, (option) => (
      <div className='ml-4 w-full flex flex-col justify-start'>
        <p className='text-sms font-normal text-white'>
          {prefix}
          {OrganizerUtils.GetDisplayName(option.organizer)}
        </p>
        <p className='text-3xs font-medium text-secondary'>
          {option.organizer.email}
        </p>
      </div>
    ))
    .with({ kind: 'slack-user' }, (option) => (
      <div className='ml-4 w-full flex flex-col justify-start'>
        <p className='text-sms font-normal text-white'>
          {`${prefix}${option.user.fullName}`}
        </p>
        <p className='text-3xs font-medium text-secondary'>
          {option.user.email}
        </p>
      </div>
    ))
    .with({ kind: 'slack-channel' }, (option) => (
      <div className='ml-4 w-full flex flex-col justify-start'>
        <span className='text-sms font-normal text-white'>
          {prefix}#{option.channel.name}
        </span>
        <span className='text-3xs font-medium text-secondary'>
          {option.users.length} {pluralize('user', option.users.length)} found
        </span>
      </div>
    ))
    .with({ __isNew__: true }, (option) => (
      <div className='ml-4 w-full flex flex-col justify-start'>
        <p className='text-sms font-normal text-white'>
          Invite {prefix}
          {option.value}
        </p>
      </div>
    ))
    .exhaustive();
}

function formatOptionValue(
  option: OrganizerSelectOption,
  prefixFormatter?: OptionPrefixFormatter
) {
  const formatted = match(option)
    .with({ kind: 'organizer' }, (option) =>
      OrganizerUtils.GetDisplayName(option.organizer)
    )
    .with({ kind: 'slack-user' }, (option) => `${option.user.fullName}`)
    .with({ kind: 'slack-channel' }, (option) => `#${option.channel.name}`)
    .with({ __isNew__: true }, (option) => option.value)
    .exhaustive();
  return `${prefixFormatter?.(option)}${formatted}`;
}

function getOptionIdentify(option: OrganizerSelectOption) {
  return match(option)
    .with({ kind: 'organizer' }, (option) => option.organizer.uid)
    .with(
      { kind: 'slack-user' },
      (option) => option.user.organizer?.uid || option.user.id
    )
    .with({ kind: 'slack-channel' }, (option) => option.channel.id)
    .with({ __isNew__: true }, (option) => option.value)
    .exhaustive();
}

async function searchSlackUsers(
  orgId: string,
  q: string
): Promise<GroupedOption | null> {
  if (q.startsWith('#')) return null;
  if (q.startsWith('@')) q = q.substring(1);
  if (!q) return null;

  const users = (
    await apiService.slack.queryUsers({
      orgId: orgId,
      type: 'byKeywords',
      keyword: q.startsWith('@') ? q.substring(1) : q,
      withOrganizer: true,
      size: 100,
    })
  ).data.users;

  return {
    kind: 'slack-users',
    label: 'Users',
    options: users.map((u) => ({
      kind: 'slack-user',
      user: u,
    })),
  };
}

async function searchSlackChannels(
  orgId: string,
  q: string
): Promise<GroupedOption | null> {
  if (q.startsWith('@')) return null;
  if (q.startsWith('#')) q = q.substring(1);
  if (!q) return null;

  const { channels } = (
    await apiService.slack.searchChannels({
      orgId: orgId,
      types: 'public',
      keyword: q,
      size: 3,
    })
  ).data;

  const options: OptionSlackChannel[] = await Promise.all(
    channels.map(async (c) => {
      const resp = await apiService.slack.queryUsers({
        orgId: orgId,
        type: 'byChannelId',
        channelId: c.id,
        size: 100,
        withOrganizer: true,
      });
      return {
        kind: 'slack-channel',
        channel: c,
        users: resp.data.users,
      };
    })
  );

  return {
    kind: 'channels',
    label: 'Channels',
    options,
  };
}

async function search(orgId: string, q: string): Promise<GroupedOption[]> {
  const options = await Promise.all([
    searchSlackChannels(orgId, q),
    searchSlackUsers(orgId, q),
  ]);
  return options.filter((o) => !!o) as GroupedOption[];
}

const searchDebounce = debounce(
  async (
    orgId: string,
    q: string,
    callback: (options: GroupedOption[]) => void
  ) => {
    const options = await search(orgId, q);
    callback(options);
  },
  200
);

export interface OrganizerGroupSelectProps {
  orgId: string;
  options: OrganizerSelectOption[];
  onChange: (
    value: OrganizerSelectOption[],
    action: ActionMeta<OrganizerSelectOption>
  ) => void;
  isOptionDisabled?: (option: OrganizerSelectOption) => boolean;
  filterOrganizer?: (organizer: Organizer) => boolean;
  placeholder?: React.ReactNode;
  autofocus?: boolean;
  className?: string;
  creatable?: boolean;
}

export function OrganizerGroupSelect(
  props: OrganizerGroupSelectProps
): JSX.Element {
  const {
    orgId,
    options,
    isOptionDisabled,
    filterOrganizer,
    onChange,
    placeholder,
    autofocus,
    className,
    creatable,
  } = props;

  const analytics = useSlackAnalytics();

  const styles = useInstance(() =>
    buildReactSelectStyles<OrganizerSelectOption, true>({
      override: {
        control: {
          height: '100%',
        },
        valueContainer: {
          width: '100%',
          padding: '10px',
          alignContent: 'flex-start',
          overflow: 'visible',
        },
        multiValue: (data) => ({
          background:
            data.kind === 'slack-user' && !creatable ? '#EE3529' : '#01ACC4',
        }),
      },
    })
  );

  const filterOption = (option: OrganizerSelectOption) => {
    switch (option.kind) {
      case 'organizer':
        return !filterOrganizer || filterOrganizer(option.organizer);
      case 'slack-user':
        return (
          !filterOrganizer ||
          !option.user.organizer ||
          filterOrganizer(fromDTOOrganizer(option.user.organizer))
        );
      case 'slack-channel':
      case undefined:
        return true;
      default:
        assertExhaustive(option);
        return true;
    }
  };

  const handleChange = (
    value: MultiValue<OrganizerSelectOption>,
    action: ActionMeta<OrganizerSelectOption>
  ) => {
    if (action.action === 'select-option') {
      analytics.trackSlackSearchSelected({
        kind: action.option?.kind,
      });
    }

    const options: OrganizerSelectOption[] = [];

    for (const option of value) {
      if (option.kind === 'organizer') {
        options.push(option);
      }
      if (option.kind === 'slack-user') {
        if (!!option.user.organizer) {
          options.push({
            kind: 'organizer',
            organizer: fromDTOOrganizer(option.user.organizer),
            source: 'slack-user',
          });
        } else {
          options.push(option);
        }
      }
      if (option.kind === 'slack-channel') {
        for (const user of option.users) {
          if (!!user.organizer) {
            options.push({
              kind: 'organizer',
              organizer: fromDTOOrganizer(user.organizer),
              source: 'slack-channel',
            });
          } else {
            options.push({
              kind: 'slack-user',
              user,
            });
          }
        }
      }
      if (option.kind === undefined) {
        options.push(option);
      }
    }

    const sorted = options.sort((a, b) =>
      formatOptionValue(a).localeCompare(formatOptionValue(b))
    );
    const unique = uniqBy(sorted, (u) => getOptionIdentify(u));
    const filtered = unique.filter(filterOption);

    onChange(filtered, action);
  };

  const prefixFormatter = (option: OrganizerSelectOption) => {
    if (!creatable) return '';
    const kind = option.kind;
    switch (kind) {
      case 'organizer':
      case 'slack-channel':
        return '';
      case 'slack-user':
        return !!option.user.organizer ? '' : '✉️ ';
      case undefined:
        return '✉️ ';
      default:
        assertExhaustive(kind);
        return '';
    }
  };

  const Select = creatable ? AsyncCreatableSelect : AsyncSelect;

  return (
    <Select<OrganizerSelectOption, true>
      value={options}
      loadOptions={(q, callback) => {
        searchDebounce(orgId, q, callback);
      }}
      isOptionDisabled={isOptionDisabled}
      filterOption={(option) => filterOption(option.data)}
      onChange={handleChange}
      isMulti
      isClearable
      cacheOptions
      placeholder={placeholder}
      styles={styles}
      classNamePrefix='select-box-v2'
      className={`${className}`}
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No users or channels matched';
      }}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={(option, meta) =>
        match(meta.context)
          .with('menu', () => formatMenu(option, prefixFormatter))
          .with('value', () => formatOptionValue(option, prefixFormatter))
          .exhaustive()
      }
      getOptionValue={getOptionIdentify}
      autoFocus={autofocus}
      isValidNewOption={(v) => isValidEmail(v)}
      components={{
        MultiValueContainer: (props) =>
          match(props.data.kind)
            .with('slack-user', () => (
              <div className='relative flex justify-center group'>
                <components.MultiValueContainer {...props} />

                {!creatable && (
                  <div className='absolute z-5 hidden group-hover:flex bottom-full'>
                    <Tooltip
                      position={'top'}
                      backgroundColor='black'
                      borderRadius={4}
                      borderColor={'rgba(255, 255, 255, 0.4)'}
                      borderWidth={1}
                    >
                      <p className='px-2 py-1 text-3xs whitespace-nowrap text-white'>
                        This invitee does not have a Luna Park account. <br />
                        Please invite them to Luna Park before adding them to a
                        scheduled game.
                      </p>
                    </Tooltip>
                  </div>
                )}
              </div>
            ))
            .otherwise(() => <components.MultiValueContainer {...props} />),
      }}
    />
  );
}
