import isEqual from 'lodash/isEqual';
import { useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';

import {
  EnumsSharedAssetPurpose,
  type ModelsSettingsSharedTTSScripts,
  type ModelsTTSLabeledRenderSettings,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';

import { useSettingsData } from '../../components/DynamicSettings/hooks/useSettingsData';
import { makeExampleVariableRegistrySnapshot } from '../../components/Game/hooks/useCreateGameInfoSnapshot';
import { FilledCheckIcon } from '../../components/icons/CheckIcon';
import { Loading } from '../../components/Loading';
import { useOpenShareAssetPickerModal } from '../../components/SharedAsset';
import {
  type ScriptScenarioOption,
  TTSScriptsEditor,
} from '../../components/VoiceOver/TTSScriptsEditor';
import { hasVariables } from '../../components/VoiceOver/VariableRegistry';
import { useLiveCallback } from '../../hooks/useLiveCallback';

export function AdminSharedScripts() {
  const { data, isLoading, error, updateValue } =
    useSettingsData('shared-tts-scripts');

  const handleSave = useLiveCallback(
    async (data: ModelsSettingsSharedTTSScripts) => {
      await updateValue({
        key: 'ttsScripts',
        value: data.ttsScripts,
      });
    }
  );

  if (isLoading) {
    return (
      <div className='fixed inset-0 flex items-center justify-center bg-lp-black-001 z-50'>
        <Loading text='' />
      </div>
    );
  } else if (error || !data) {
    return (
      <div className='w-full h-full flex items-center justify-center text-red-002'>
        Error loading shared scripts
      </div>
    );
  }

  return <Editor initial={data.ttsScripts} onSave={handleSave} />;
}

const supportedVariables = makeExampleVariableRegistrySnapshot();

const sharedScriptOptions: ScriptScenarioOption[] = [
  {
    label: 'Opening Title - Single',
    description:
      'Opening Title voice overs are prepended with %positionAwareOpeningTitleScript%. These scripts define the possible values for this variable when there is a single unit in the session.',
    tags: ['positionAwareOpeningTitleScript', 'single'],
    supportedVariables,
  },
  {
    label: 'Opening Title - First',
    description:
      'Opening Title voice overs are prepended with %positionAwareOpeningTitleScript%. These scripts define the possible values for this variable for the first unit in a multiple unit session.',
    tags: ['positionAwareOpeningTitleScript', 'first'],
    supportedVariables,
  },
  {
    label: 'Opening Title - Mid',
    description:
      'Opening Title voice overs are prepended with %positionAwareOpeningTitleScript%. These scripts define the possible values for this variable for the middle units in a multiple unit session.',
    tags: ['positionAwareOpeningTitleScript', 'mid'],
    supportedVariables,
  },
  {
    label: 'Opening Title - Last',
    description:
      'Opening Title voice overs are prepended with %positionAwareOpeningTitleScript%. These scripts define the possible values for this variable for the last unit in a multiple unit session.',
    tags: ['positionAwareOpeningTitleScript', 'last'],
    supportedVariables,
  },
  {
    label: 'Scoreboard – Single',
    description:
      'Scoreboard voice overs are prepended with %positionAwareScoreboardScript%. These scripts define the possible values for this variable when there is a single unit in the session.',
    tags: ['positionAwareScoreboardScript', 'single'],
    supportedVariables,
  },
  {
    label: 'Scoreboard – First',
    description:
      'Scoreboard voice overs are prepended with %positionAwareScoreboardScript%. These scripts define the possible values for this variable for the first unit in a multiple unit session.',
    tags: ['positionAwareScoreboardScript', 'first'],
    supportedVariables,
  },
  {
    label: 'Scoreboard – Mid',
    description:
      'Scoreboard voice overs are prepended with %positionAwareScoreboardScript%. These scripts define the possible values for this variable for the middle units in a multiple unit session.',
    tags: ['positionAwareScoreboardScript', 'mid'],
    supportedVariables,
  },
  {
    label: 'Scoreboard – Last',
    description:
      'Scoreboard voice overs are prepended with %positionAwareScoreboardScript%. These scripts define the possible values for this variable for the last unit in a multiple unit session.',
    tags: ['positionAwareScoreboardScript', 'last'],
    supportedVariables,
  },
  {
    label: 'Hosted Instructions Transition',
    description:
      'Note that Hosted Instruction voice overs are prepended with %transitionToHostedInstructions%. These scripts define the possible values for this variable.',
    tags: ['transitionToHostedInstructions'],
    supportedVariables,
  },
];

function Editor(props: {
  initial: ModelsTTSScript[] | null | undefined;
  onSave: SubmitHandler<ModelsSettingsSharedTTSScripts>;
}) {
  const { control, formState, handleSubmit, reset } =
    useForm<ModelsSettingsSharedTTSScripts>({
      defaultValues: {
        ttsScripts: props.initial ?? [],
      },
    });

  const { isDirty, isSubmitting, isSubmitSuccessful } = formState;
  const onSubmit = handleSubmit(async (data) => {
    await props.onSave(data);
    reset(data);
  });

  const [previewTTSSettings, setPreviewTTSSettings] =
    useState<ModelsTTSLabeledRenderSettings | null>(null);

  return (
    <div className='relative w-full px-10 text-white flex flex-col gap-4'>
      <header className='w-full flex items-center justify-between pb-4'>
        <div className='flex-1'>
          <h1 className='text-3xl font-bold'>Shared Scripts</h1>
          <h2 className='text-base text-icon-gray'>
            Manage shared host scripts.
          </h2>
        </div>

        <button
          type='button'
          className='btn-primary w-40 h-10'
          disabled={!isDirty || isSubmitting}
          onClick={onSubmit}
        >
          {isSubmitting ? (
            'Saving...'
          ) : isSubmitSuccessful && !isDirty ? (
            <div className='flex items-center justify-center gap-1'>
              <FilledCheckIcon />
              Saved
            </div>
          ) : (
            'Save'
          )}
        </button>
      </header>

      <div className='w-full pb-10'>
        <Controller<ModelsSettingsSharedTTSScripts, 'ttsScripts'>
          name='ttsScripts'
          control={control}
          render={({ field: { value, onChange } }) => {
            const handleAdd = (script: ModelsTTSScript) => {
              onChange([...(value ?? []), script]);
            };

            const handleRemove = (script: ModelsTTSScript) => {
              onChange(value?.filter((s) => s.id !== script.id));
            };

            const handleChange = (
              prev: ModelsTTSScript,
              next: ModelsTTSScript
            ) => {
              const nextTTSScripts = [...(value ?? [])];
              const index = nextTTSScripts.findIndex((s) => s.id === prev.id);
              if (index === -1) return;
              if (!isEqual(prev, next)) {
                // only update the field if there's a change.
                nextTTSScripts[index] = next;
                onChange(nextTTSScripts);
              }
            };

            const handleUpload = (ttsScripts: ModelsTTSScript[]) => {
              onChange(ttsScripts);
            };

            return (
              <TTSScriptsEditor
                title='Shared Scripts'
                description={
                  <>
                    <span>
                      There are various times in the experience where we inject
                      variables into scripts to create a smoother experience.
                      Use this editor to define the options for those moments.
                    </span>

                    <div className='pt-4'>
                      <SelectPreviewVoice
                        value={previewTTSSettings}
                        onChange={setPreviewTTSSettings}
                      />
                    </div>
                  </>
                }
                value={value}
                scenarioOptions={sharedScriptOptions}
                onAdd={handleAdd}
                onRemove={handleRemove}
                onChange={handleChange}
                onUpload={handleUpload}
                ttsRenderSettings={previewTTSSettings}
                csvDownloadName={`shared-scripts-${Date.now()}.csv`}
                checkForFallbacks={(query, selected) => {
                  const ok = query
                    .select(selected?.tags ?? [])
                    .some((s) => !hasVariables(s.script));
                  if (ok) return { ok };
                  return {
                    ok,
                    message:
                      'No fallback script defined. Define at least one script without variables.',
                  };
                }}
              />
            );
          }}
        />
      </div>
    </div>
  );
}

function SelectPreviewVoice(props: {
  value: ModelsTTSLabeledRenderSettings | null;
  onChange: (value: ModelsTTSLabeledRenderSettings | null) => void;
}) {
  const openShareAssetPickerModal = useOpenShareAssetPickerModal();
  const onClickSetPreviewVoice = useLiveCallback(async () => {
    openShareAssetPickerModal({
      purposes: [EnumsSharedAssetPurpose.SharedAssetPurposeVoice],
      onSelected(item) {
        if (!item.data?.ttsRenderSettings) return;
        props.onChange({
          id: item.id,
          label: item.label,
          ...item.data.ttsRenderSettings,
        });
      },
    });
  });

  return (
    <div className='flex items-center gap-2'>
      <span>Preview Voice:</span>
      <span
        className='px-1.5 bg-primary text-white rounded cursor-pointer'
        onClick={onClickSetPreviewVoice}
      >
        {props.value?.label ?? 'Select Voice'}
      </span>
    </div>
  );
}
