import { useState } from 'react';

import { type Media, type MediaData } from '@lp-lib/media';

import { MiniMediaEditor } from '../../components/MediaUploader/MiniMediaEditor';
import { assertExhaustive } from '../../utils/common';

function TestMediaUploader(props: { type: 'video' | 'audio' }) {
  const [media, setMedia] = useState<Media | null>(null);
  const [mediaData, setMediaData] = useState<MediaData | null>(null);

  switch (props.type) {
    case 'video':
      return (
        <MiniMediaEditor
          id='test-video-uploader'
          video
          media={media}
          mediaData={mediaData}
          volumeSelectable
          onChange={(mediaData, media) => {
            setMedia(media);
            setMediaData(mediaData);
          }}
        />
      );
    case 'audio':
      return (
        <MiniMediaEditor
          id='test-audio-uploader'
          audio
          media={media}
          mediaData={mediaData}
          volumeSelectable
          onChange={(mediaData, media) => {
            setMedia(media);
            setMediaData(mediaData);
          }}
        />
      );
    default:
      assertExhaustive(props.type);
      return null;
  }
}

export function MediaUpload(): JSX.Element {
  return (
    <div className='w-full h-full text-white px-20 flex gap-20'>
      <div className='flex flex-col gap-2'>
        <div>Upload Image/Video</div>
        <TestMediaUploader type='video' />
      </div>
      <div className='flex flex-col gap-2'>
        <div>Upload Audio</div>
        <TestMediaUploader type='audio' />
      </div>
    </div>
  );
}
